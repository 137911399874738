import { Actor, HttpAgent } from "@dfinity/agent";
import vestingIDL from "./candid/ogy_vesting_canister.did.js";

// mainnet
export const OGY_VESTING_CANISTER_ID = process.env.VESTING_CANISTER_ID || "";

export const getVestingActor = (identity) => {
  const agent = new HttpAgent({
    identity,
    host: "https://boundary.ic0.app/",
  });
  return Actor.createActor(vestingIDL as any, {
    agent,
    canisterId: OGY_VESTING_CANISTER_ID,
  });
};
