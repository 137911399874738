import { OGY_LEDGER_CANISTER_ID } from "../../utils/ledger";
import { OGY_GOVERNANCE_CANISTER_ID } from "../../utils/governance";
import { OGY_VESTING_CANISTER_ID } from "../../utils/vesting";
import ledgerIDL from "../../utils/candid/ledger.did.js";
import { idlFactory } from "../../utils/candid/airdrop/airdrop.did.js";
import idlFactoryGovernance from "../../utils/candid/ogy_governance_canister.did.js";
import idlFactoryVesting from "../../utils/candid/ogy_vesting_canister.did.js";

const usePlug = () => {
  const connectPlug = async () => {
    if (!(await window.ic.plug.isConnected())) {
      const connected = await window.ic.plug.requestConnect({
        whitelist: [OGY_LEDGER_CANISTER_ID, process.env.AIRDROP_CANISTER_ID, process.env.GOVERNANCE_CANISTER_ID, process.env.VESTING_CANISTER_ID],
      });
      if (connected === "denied")
        throw new Error("Error with plug.requestConnect");
    }

    await window.ic.plug.createAgent({
      whitelist: [OGY_LEDGER_CANISTER_ID, process.env.AIRDROP_CANISTER_ID, process.env.GOVERNANCE_CANISTER_ID, process.env.VESTING_CANISTER_ID],
      host: "https://boundary.ic0.app",
    });

    const actor = await window.ic.plug.createActor({
      canisterId: OGY_LEDGER_CANISTER_ID,
      interfaceFactory: ledgerIDL,
    });

    const governanceActor = await window.ic.plug.createActor({
      canisterId: OGY_GOVERNANCE_CANISTER_ID,
      interfaceFactory: idlFactoryGovernance,
    });

    const vestingActor = await window.ic.plug.createActor({
      canisterId: OGY_VESTING_CANISTER_ID,
      interfaceFactory: idlFactoryVesting,
    });

    const plugPrincipal = await window.ic.plug.agent.getPrincipal();
    window.localStorage.setItem("loggedIn", "plug");

    return {
      principal: plugPrincipal,
      actor,
      governanceActor,
      vestingActor
    };
  };

  return connectPlug;
};

export default usePlug;
