import { useContext } from "react";
import { AuthContext } from "../auth";
import { getLedgerActor } from "../../utils/ledger";
import { AnonymousIdentity } from "@dfinity/agent";
import { getAccountId } from "../../utils/principalToAccountID";

const usePlugBalance = () => {
  const {} = useContext(AuthContext);

  const getBalance = async () => {
    try {
      const principalId = await window.ic.plug.agent.getPrincipal();
      const ledger = getLedgerActor(new AnonymousIdentity());
      const ogyBalance = (await ledger.account_balance_dfx({
        account: getAccountId(principalId),
      })) as { e8s: BigInt };

      console.log({ ogyBalance });

      return ogyBalance.e8s;
    } catch (e) {
      console.log(e);
    }
  };

  return getBalance;
};

export default usePlugBalance;
