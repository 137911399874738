import { AuthClient } from "@dfinity/auth-client";
import { useContext } from "react";
import { getLedgerActor } from "../../utils/ledger";
import { getGovernanceActor, getVestingActor } from "../../utils/actors";
import { getAccountId } from "../../utils/principalToAccountID";
import { GOVERNANCE_CANISTER_ID } from "../../utils/actors";
import { AuthContext } from "../auth";
import { Principal } from "@dfinity/principal";

export const useSendOGY = () => {
  const { principal, walletType, ledger } = useContext(AuthContext);

  const sendOGY = async (
    amount_e8s: BigInt,
    fee_e8s: BigInt,
    recipient: String,
    memo: BigInt
  ) => {
    if (!principal) {
      throw Error("sendOGY failed: principal undefined");
    }
    switch (walletType) {
      case "stoic":
        alert("stoic send not supported yet");
        break;
      case "plug":
        try {
          const blockHeight = await ledger.send_dfx({
            to: recipient,
            fee: {
              e8s: fee_e8s,
            },
            memo: memo,
            from_subaccount: [],
            created_at_time: [],
            amount: { e8s: amount_e8s },
          });

          console.log({ blockHeight });

          return blockHeight;
        } catch (e) {
          console.error({ e });
          throw e;
        }
        break;
      case "ii":
        {
          try {
            const authClient = await AuthClient.create();
            const identity = authClient.getIdentity();
            console.log(getAccountId(principal));
            const ledger = getLedgerActor(identity);
            // 'to' : AccountIdentifier,
            // 'fee' : ICPTs,
            // 'memo' : Memo,
            // 'from_subaccount' : IDL.Opt(SubAccount),
            // 'created_at_time' : IDL.Opt(TimeStamp),
            // 'amount' : ICPTs,
            const blockHeight = await ledger.send_dfx({
              to: recipient,
              fee: {
                e8s: fee_e8s,
              },
              memo: memo,
              from_subaccount: [],
              created_at_time: [],
              amount: { e8s: amount_e8s },
            });
            // "Invalid record {to:text; fee:record {e8s:nat64}; memo:nat64; from_subaccount:opt vec nat8; created_at_time:opt record {timestamp_nanos:nat64}; amount:record {e8s:nat64}} argument: {"to":"f75735c3578507ba95fdae60b32e1e1255c37dd8215b329c353c9f17b009e03c","fee":{"e8s":"BigInt(200000)"},"memo":"23","from_subaccount":[],"created_at_time":[],"amount":{"e8s":"BigInt(300)"}}"

            console.log({ blockHeight });

            return blockHeight;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      default:
        throw new Error("not implemented");
    }
  };

  return sendOGY;
};

export const useGovernanceDepositOGY = () => {
  const { principal, walletType, ledger, governance } = useContext(AuthContext);

  const governanceDepositOGY = async (amount_e8s: BigInt, fee_e8s: BigInt) => {
    if (!principal) {
      throw Error("sendOGY failed: principal undefined");
    }
    switch (walletType) {
      case "stoic":
        alert("stoic send not supported yet");
        break;
      case "plug":
        {
          try {

            // 'to' : AccountIdentifier,
            // 'fee' : ICPTs,
            // 'memo' : Memo,
            // 'from_subaccount' : IDL.Opt(SubAccount),
            // 'created_at_time' : IDL.Opt(TimeStamp),
            // 'amount' : ICPTs,
            const blockHeight = await ledger.send_dfx({
              to: getAccountId(Principal.fromText(GOVERNANCE_CANISTER_ID)),
              fee: {
                e8s: fee_e8s,
              },
              memo: 0,
              from_subaccount: [],
              created_at_time: [],
              amount: { e8s: amount_e8s },
            });
            // "Invalid record {to:text; fee:record {e8s:nat64}; memo:nat64; from_subaccount:opt vec nat8; created_at_time:opt record {timestamp_nanos:nat64}; amount:record {e8s:nat64}} argument: {"to":"f75735c3578507ba95fdae60b32e1e1255c37dd8215b329c353c9f17b009e03c","fee":{"e8s":"BigInt(200000)"},"memo":"23","from_subaccount":[],"created_at_time":[],"amount":{"e8s":"BigInt(300)"}}"

            console.log({ blockHeight });

            
            const result = await governance.redeem_deposit(
              principal,
              [],
              blockHeight
            );
            // redeem_deposit(principal: Principal, subaccount: ?[Nat8], _block: Nat64)

            return result;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        
        break;
      case "ii":
        {
          try {

            // 'to' : AccountIdentifier,
            // 'fee' : ICPTs,
            // 'memo' : Memo,
            // 'from_subaccount' : IDL.Opt(SubAccount),
            // 'created_at_time' : IDL.Opt(TimeStamp),
            // 'amount' : ICPTs,
            const blockHeight = await ledger.send_dfx({
              to: getAccountId(Principal.fromText(GOVERNANCE_CANISTER_ID)),
              fee: {
                e8s: fee_e8s,
              },
              memo: 0,
              from_subaccount: [],
              created_at_time: [],
              amount: { e8s: amount_e8s },
            });
            // "Invalid record {to:text; fee:record {e8s:nat64}; memo:nat64; from_subaccount:opt vec nat8; created_at_time:opt record {timestamp_nanos:nat64}; amount:record {e8s:nat64}} argument: {"to":"f75735c3578507ba95fdae60b32e1e1255c37dd8215b329c353c9f17b009e03c","fee":{"e8s":"BigInt(200000)"},"memo":"23","from_subaccount":[],"created_at_time":[],"amount":{"e8s":"BigInt(300)"}}"

            console.log({ blockHeight });

            
            const result = await governance.redeem_deposit(
              principal,
              [],
              blockHeight
            );
            // redeem_deposit(principal: Principal, subaccount: ?[Nat8], _block: Nat64)

            return result;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      default:
        throw new Error("not implemented");
    }
  };

  return governanceDepositOGY;
};

export const useGovernanceActor = () => {
  const { principal, walletType, governance } = useContext(AuthContext);
  const _governanceActor = async () => {
    if (!principal) {
      throw Error("useGovernanceActor failed: principal undefined");
    }
    switch (walletType) {
      case "stoic":
        alert("stoic send not supported yet");
        break;
      case "plug":
        {
          try {
            
            return governance;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      case "ii":
        {
          try {
            
            return governance;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      default:
        throw new Error("not implemented");
    }
  };

  return { _governanceActor };
};

export const useGovernanceWithdrawOGY = () => {
  const { principal, walletType, governance } = useContext(AuthContext);

  const governanceWithdrawOGY = async (
    amount_e8s: BigInt,
    recipient: string
  ) => {
    if (!principal) {
      throw Error("sendOGY failed: principal undefined");
    }
    switch (walletType) {
      case "stoic":
        alert("stoic send not supported yet");
        break;
      case "plug":
        {
          try {
            
            const result = await governance.withdraw_unlocked_tokens(
              recipient,
              amount_e8s
            );
            // public shared (msg) func withdraw_unlocked_tokens(_recipient: AccountIdentifier, _amount: Nat64): async (Nat64) {

            return result;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      case "ii":
        {
          try {
            
            const result = await governance.withdraw_unlocked_tokens(
              recipient,
              amount_e8s
            );
            // public shared (msg) func withdraw_unlocked_tokens(_recipient: AccountIdentifier, _amount: Nat64): async (Nat64) {

            return result;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      default:
        throw new Error("not implemented");
    }
  };

  return governanceWithdrawOGY;
};

export const useVestingActor = () => {
  const { principal, walletType, vesting } = useContext(AuthContext);

  const stakeClaimableTokens = async (
    indexes: BigInt[],
    recipientPrincipal?: Principal
  ) => {
    if (!principal) {
      throw Error("stakeClaimableTokens failed: principal undefined");
    }
    switch (walletType) {
      case "stoic":
        alert("stoic send not supported yet");
        break;
      case "plug":
        {
          try {
            
            const result = await Promise.all(
              indexes.map((index) =>
                vesting.stake_claimable_tokens(
                  index,
                  recipientPrincipal ? [recipientPrincipal] : []
                )
              )
            );

            return result;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      case "ii":
        {
          try {
            
            const result = await Promise.all(
              indexes.map((index) =>
                vesting.stake_claimable_tokens(
                  index,
                  recipientPrincipal ? [recipientPrincipal] : []
                )
              )
            );

            return result;
          } catch (e) {
            console.error({ e });
            throw e;
          }
        }
        break;
      default:
        throw new Error("not implemented");
    }
  };

  const _vestingActor = async () => {
    if (!principal) {
      throw Error("stakeClaimableTokens failed: principal undefined");
    }
    return vesting;
  };

  return { stakeClaimableTokens, _vestingActor };
};
