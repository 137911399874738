import { Actor, HttpAgent } from "@dfinity/agent";
import governanceIDL from "./candid/ogy_governance_canister.did.js";

// mainnet
export const OGY_GOVERNANCE_CANISTER_ID = process.env.GOVERNANCE_CANISTER_ID || "";

export const getGovernanceActor = (identity) => {
  const agent = new HttpAgent({
    identity,
    host: "https://boundary.ic0.app/",
  });
  return Actor.createActor(governanceIDL as any, {
    agent,
    canisterId: OGY_GOVERNANCE_CANISTER_ID,
  });
};
