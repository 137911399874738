import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import Card from "@origyn-sa/ui/components/atoms/Card";
import Flex from "@origyn-sa/ui/components/atoms/Flex";
import Button from "@origyn-sa/ui/components/molecules/Button";
import {H1, H2, H3} from "@origyn-sa/ui/components/atoms/H";
import HR from "@origyn-sa/ui/components/atoms/HR";
import Block from "@origyn-sa/ui/components/atoms/Block";
import Grid from "@origyn-sa/ui/components/atoms/Grid";
import useMetrics from "../../hooks/metrics";
import Slider from "../../components/Slider";
import {AuthContext} from "../../hooks/auth";
import useBalance from "../../hooks/balance";

const Home = () => {
  const {logIn, loggedIn, walletType, account, logOut, principal, isLoading} = useContext(AuthContext);
  const {walletBalance, stakedTokens} = useBalance();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && !loggedIn) {
      history.push("/login");
    }
  }, [loggedIn, isLoading])

  return (
    <div>
      <Flex
        gap={8}
        align="center"
        justify="center"
        textAlign="center"
        column
        columnSsm={false}
      >
        <div>
          <H2>Total OGY</H2>
          <H3>100 000 000</H3>
        </div>
        <HR margin={0}/>
        <div>
          <H2>Transactions</H2>
          <H3>143 123</H3>
        </div>
        <HR margin={0}/>
        <div>
          <H2>Holders</H2>
          <H3>1 123</H3>
        </div>
      </Flex>
    </div>
  );
};

export default Home;
