import React, {useContext, useState} from "react";
import styled from "styled-components";
import { Link , useHistory} from "react-router-dom";
import LogOutIcon from "@origyn-sa/ui/icons/logout";
import Flex from "@origyn-sa/ui/components/atoms/Flex/index";
import Profile from "@origyn-sa/ui/components/organisms/Profile/index";
import { AuthContext } from "../hooks/auth";
import WalletIcon from "@origyn-sa/ui/icons/wallet";

export type ProfileProps = {};

const nav = [
  { label: "Wallet", Icon: WalletIcon, url: "/account" },
];


const ProfileNavigation:React.FC<ProfileProps> = () => {
  const { loggedIn, principal, logOut } = useContext(AuthContext);
  const profileNav = [
    {
      label: "Log out",
      Icon: LogOutIcon,
      url: "/login",
      separate: true,
      onItemSelect: logOut
    },
  ];

  return (
    <Flex hide={loggedIn} hideXsm={false} margin={[, , 2.5]} justify='space-between'>
      {
        loggedIn ? (
          <>
            <span></span>
            <Profile principalId={principal?.toString().substr(0, 12) + "..."} navigation={profileNav} />
          </>
        ) : (
          <>
            <Link to="/"><img src="/images/logo.png" alt=""/></Link>
            <Link to="/login">Log In</Link>
          </>
        )
      }
    </Flex>
  );
};
export default ProfileNavigation;
