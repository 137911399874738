import React, { useState, useEffect, createContext } from "react";
import { Actor, HttpAgent, AnonymousIdentity } from "@dfinity/agent";
import { Principal } from "@dfinity/principal";
import {useHistory} from "react-router-dom";
import usePlug from "./plug";
import useStoic from "./stoic";
import useInternetIdentity from "./internedIdentity";
import {toast} from "@origyn-sa/ui/utils/toaster";
import Preloader from "../../components/Preloader";
import {AuthClient} from "@dfinity/auth-client";
import { idlFactory } from "../../utils/candid/airdrop/airdrop.did.js";

const canisterId = process.env.AIRDROP_CANISTER_ID;
const canisterIdGovernance = process.env.GOVERNANCE_CANISTER_ID;
const canisterIdVesting = process.env.VESTING_CANISTER_ID;
const canisterIdLedger = process.env.LEDGER_CANISTER_ID;
const createActor = (canisterId: string) => {
  const agent = new HttpAgent({
    host: "https://boundary.ic0.app/",
  });

  // Creates an actor with using the candid interface and the HttpAgent
  return Actor.createActor(idlFactory, {
    agent,
    canisterId,
  });
};

interface authContextType {
  isLoading: boolean;
  loggedIn: boolean;
  logIn: (wallet: string) => Promise<void>;
  isAuthorized?: boolean;
  principal?: Principal;
  ledger?: any;
  airdrop?: any;
  actor?: any;
  governance?: any;
  vesting?: any;
  logOut: () => void;
  walletType?: string;
  accounts?: Array<any>;
  account?: any;
}

export const AuthContext = createContext<authContextType>({
  loggedIn: false,
  isLoading: false,
  airdrop: createActor(canisterId || ""),
  vesting: createActor(canisterIdVesting || ""),
  governance: createActor(canisterIdGovernance || ""),
  actor: createActor(canisterIdLedger || ""),
  logIn: async () => {},
  logOut: () => {},
});

export const useAuth = () => {
  const loggedInStatus = localStorage.getItem("loggedIn");
  const [isLoading, setIsLoading] = useState(!!(loggedInStatus && loggedInStatus !== "false"));
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const [ledger, setLedger] = useState<any>(createActor(canisterId || ""));
  const [governance, setGovernance] = useState<any>(createActor(canisterId || ""));
  const [vesting, setVesting] = useState<any>(createActor(canisterId || ""));
  const [airdrop, setAirdrop] = useState<any>(createActor(canisterId || ""));
  const [principal, setPrincipal] = useState<Principal | undefined>();
  const [walletType, setWalletType] = useState<string | undefined>();
  const [walletAccounts, setWalletAccounts] = useState<any>();
  const [currentWalletAccount, setCurrentWalletAccount] = useState<any>();
  const connectPlug = usePlug();
  const connectStoic = useStoic();
  const connectInternetIdentity = useInternetIdentity();
  const history = useHistory();

  const logIn = async (wallet?: string) => {
    try {
      setIsLoading(true);
      const resolvedWallet = wallet || localStorage.getItem("loggedIn");
      setWalletType(resolvedWallet || undefined);
      switch (resolvedWallet) {
        case "ii":
          const ii = await connectInternetIdentity();
          if (ii.authorized) {
            setPrincipal(ii.principal);
            setAirdrop(ii.airdropActor);
            setGovernance(ii.governanceActor);
            setVesting(ii.vestingActor);
            setLedger(ii.actor);
            setIsAuthorized(true);
            setLoggedIn(true);
          } else {
            setIsAuthorized(false);
          }
          break;
        case "plug":
          const p = await connectPlug();
          setPrincipal(p.principal);
          setLedger(p.actor);
          setGovernance(p.governanceActor);
          setVesting(p.vestingActor);
          setLoggedIn(true);
          break;
        case "stoic":
          const s = await connectStoic();
          setPrincipal(s.principal);
          setWalletAccounts(s.accounts);
          // TODO: add account selection to local storage
          setCurrentWalletAccount(s.accounts[0]);
          setLoggedIn(true);
          break;
        default:
          logOut();
          console.error("Error: No wallet defined for log in function");
          break;
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Cannot authorize. Try again." + e);
      logOut();
    }
  };
  const logOut = () => {
    window.localStorage.setItem("loggedIn", "false");
    if (walletType === "ii") {
      AuthClient.create().then((authClient) => {
        authClient.logout();
      });
    }
    setIsAuthorized(undefined);
    setLoggedIn(false);
    setIsLoading(false);
    setWalletAccounts(undefined);
    setCurrentWalletAccount(undefined);
  };

  useEffect(() => {
    if (loggedInStatus && loggedInStatus !== "false") {
      logIn(loggedInStatus);
    }
  }, [localStorage.getItem("loggedIn")]);

  return {
    airdrop,
    isLoading,
    loggedIn,
    logIn,
    isAuthorized,
    principal,
    logOut,
    ledger,
    governance,
    vesting,
    walletType,
    accounts: walletAccounts,
    account: currentWalletAccount,
  };
};

export const AuthProvider: React.FC = ({children}) => {
  const auth = useAuth();
  return (
    <AuthContext.Provider value={auth}>
      {
        auth.isLoading ? (
          <Preloader />
        ) : (
          children
        )
      }
    </AuthContext.Provider>
  )
}
