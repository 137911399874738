import {useContext, useEffect, useState} from "react";
import {AuthContext} from "./auth";

const useMetrics = () => {
  const [metrics, setMetrics] = useState();

  const getMetrics = async () => {
  }

  useEffect(() => {
    getMetrics();
  }, []);

  return metrics;
};

export default useMetrics;