import { useContext } from "react";
import { StoicIdentity } from "ic-stoic-identity";
import { AuthContext } from "../auth";
import { getLedgerActor } from "../../utils/ledger";
import { formatE8S } from "../../utils";
import { AuthClient } from "@dfinity/auth-client";
import { getAccountId } from "../../utils/principalToAccountID";

const to32bits = (num) => {
  let b = new ArrayBuffer(4);
  new DataView(b).setUint32(0, num);
  return Array.from(new Uint8Array(b));
};

const useIIBalance = () => {
  const { account, principal } = useContext(AuthContext);

  const getBalance = async () => {
    if (!principal) {
      return 0;
    }
    console.log("useIIBalance", account);
    try {
      const authClient = await AuthClient.create();
      console.log(authClient);
      const identity = authClient.getIdentity();
      console.log(getAccountId(principal));
      const ledger = getLedgerActor(identity);
      const ogyBalance = (await ledger.account_balance_dfx({
        account: getAccountId(principal),
      })) as { e8s: BigInt };

      console.log({ ogyBalance });

      return ogyBalance.e8s;
    } catch (e) {
      console.log(e);
    }
  };

  return getBalance;
};

export default useIIBalance;
