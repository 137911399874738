import { useContext } from "react";
import { StoicIdentity } from "ic-stoic-identity";
import { AuthContext } from "../auth";
import { getLedgerActor } from "../../utils/ledger";
import { formatE8S } from "../../utils";
import { getAccountId } from "../../utils/principalToAccountID";
import { Principal } from "@dfinity/principal";

const to32bits = (num) => {
  let b = new ArrayBuffer(4);
  new DataView(b).setUint32(0, num);
  return Array.from(new Uint8Array(b));
};

const getSubAccountArray = (s) => {
  if (Array.isArray(s)) {
    return s.concat(Array(32 - s.length).fill(0));
  } else {
    //32 bit number only
    return Array(28)
      .fill(0)
      .concat(to32bits(s ? s : 0));
  }
};

const useStoicBalance = () => {
  const { principal } = useContext(AuthContext);

  const getBalance = async () => {
    try {
      const identity = await StoicIdentity.load();
      const ledger = getLedgerActor(identity);
      const stoicBalance = (await ledger.account_balance_dfx({
        account: getAccountId(principal as Principal),
      })) as { e8s: BigInt };

      return stoicBalance.e8s;
    } catch (e) {
      console.log(e);
    }
  };

  return getBalance;
};

export default useStoicBalance;
