import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Principal } from "@dfinity/principal";
import { H2, H3, H5 } from "@origyn-sa/ui/components/atoms/H";
import HR from "@origyn-sa/ui/components/atoms/HR";
import Grid from "@origyn-sa/ui/components/atoms/Grid";
import Card from "@origyn-sa/ui/components/atoms/Card";
import { AuthContext } from "../../hooks/auth";
import useBalance from "../../hooks/balance";
import Flex from "@origyn-sa/ui/components/atoms/Flex/index";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CopyIcon from "@material-ui/icons/FileCopy";
import makeBlockie from "ethereum-blockies-base64";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { formatE8S } from "../../utils";
import Button from "@origyn-sa/ui/components/molecules/Button/index";
import Modal from "@origyn-sa/ui/components/molecules/Modal/index";
import Input from "@origyn-sa/ui/components/molecules/Input/index";
import { timeConverter } from "../../utils/dateTime";
import { getAccountId } from "../../utils/principalToAccountID";
import { copyToClipboard } from "../../utils";
import { toast } from "@origyn-sa/ui/utils/toaster";

import CopyButton from "../../components/CopyButton";
import WalletInfo from "../../components/WalletInfo";
import { useSendOGY } from "../../hooks/send/sendOGY";
import { OGY_LEDGER_CANISTER_ID } from "../../utils/ledger";


const LongString = styled(H3)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Account = () => {
  const { logIn, loggedIn, walletType, account, logOut, principal, isLoading } =
    useContext(AuthContext);
  const sendOGY = useSendOGY();
  const [sendOpen, setSendOpen] = useState(false);
  const { walletBalance, stakedTokens } = useBalance();
  const history = useHistory();

  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState(0);
  const [memo, setMemo] = useState(0);

  const FEE = 0.002;
  const handleSend = () => setSendOpen(true);
  const handleOnCloseModal = () => {
    console.log("handleOnCloseModal");
    setSendOpen(false);
  };
  const handleSetMemo = (e) => {
    setMemo(e.target.value);
  };
  const handleSendOGY = async () => {
    console.log("handleSendOGY", { recipient, amount });
    if (recipient.length != 64) {
      return toast.error("Invalid recipient length (64)");
    }
    if (amount < 0) {
      return toast.error("Amount lower than zero");
    }
    let memo_bigint = 0n;
    try {
      memo_bigint = BigInt(memo);
    } catch (e) {
      return toast.error("Invalid memo: not a number");
    }
    const e8s = 10 ** 8;
    const amount_e8s = BigInt(Math.round(amount * e8s));
    const fee_e8s = BigInt(Math.round(FEE * e8s));
    const totalMinus = amount_e8s + fee_e8s;
    console.log({ amount_e8s, memo_bigint, fee_e8s });
    // check recipient validity
    // check BigInt(amount+fee) > walletBalance
    if ((walletBalance || 0n) < totalMinus) {
      return toast.error("Balance is insufficient...");
    }

    try {
      toast.info("Transfer in progress...");
      const blockHeight = await sendOGY(
        amount_e8s,
        fee_e8s,
        recipient,
        memo_bigint
      );
      toast.success(`Transfer successful ${blockHeight} - reloading the page`);
      setSendOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (e) {
      console.error({ e });
      toast.error(`Error while sending OGY:  ${e}`);
    }
  };

  const handleSetAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleSetRecipient = (e) => {
    setRecipient(e.target.value);
  };

  useEffect(() => {
    if (!isLoading && !loggedIn) {
      history.push("/login");
    }
  }, [loggedIn, isLoading]);

  return (
    <div>
      <Modal isOpen={sendOpen} onClose={handleOnCloseModal}>
        <Card column padding={2}>
          <H2>Send OGY</H2>
          <br />
          <H5>
            Balance: {"  "}
            {walletBalance !== undefined
              ? formatE8S(walletBalance) + " OGY"
              : "Loading..."}
          </H5>
          <br />
          <H5>Recipient</H5>
          <Input
            placeholder='Recipient Account ID'
            value={recipient}
            onChange={handleSetRecipient}
          />
          <H5>Amount (OGY)</H5>
          <Input
            placeholder='Amount OGY'
            type='number'
            value={String(amount)}
            onChange={handleSetAmount}
          />
          <H5>Fee (OGY)</H5>
          <Input disabled placeholder='Fee (OGY)' value={String(FEE)} />
          <H5>Memo (Nat64)</H5>
          <Input
            placeholder='Memo (number)'
            type='number'
            onChange={handleSetMemo}
            value={String(memo)}
          />
          <br />
          <Flex alignSelf='center'>
            <Flex gap={2}>
              <Button onClick={handleOnCloseModal}>Close</Button>
              <Button onClick={handleSendOGY}>Send OGY</Button>
            </Flex>
          </Flex>
        </Card>
      </Modal>
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Your Account
      </H2>
      <Card column padding={2}>
        <H2 textAlign='center'>Account Details.</H2>
        <Flex column>
          <H3>Principal:</H3>
          <Flex gap={1}>
            <LongString color='neutral2'>{principal?.toString()}</LongString>
            <CopyButton text={principal?.toString() || ""} />
          </Flex>
        </Flex>
        <br />
        <Flex column>
          <H3>Account ID:</H3>
          <Flex gap={1}>
            <LongString color='neutral2'>
              {getAccountId(principal as Principal)}
            </LongString>
            <CopyButton text={getAccountId(principal as Principal)} />
          </Flex>
        </Flex>
      </Card>
      <br />
      <Card column padding={2}>
        <a
          style={{ textAlign: "right" }}
          href={`https://ic.rocks/principal/${OGY_LEDGER_CANISTER_ID}`}
          target='_blank'
        >
          See OGY Ledger Canister on ic.rocks
        </a>
        <H2 textAlign='center'>Balance</H2>
        <Typography
          style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
        >
          <br />
          {walletBalance !== undefined
            ? formatE8S(walletBalance) + " OGY"
            : "Loading..."}
        </Typography>
        <br />
        <Button onClick={handleSend}>Send</Button>
      </Card>
      <br />
      <Card column padding={2}>
        <H2 textAlign='center'>Connected With</H2>
        <WalletInfo />
      </Card>
    </div>
  );
};

export default Account;
