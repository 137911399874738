import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {Principal} from '@dfinity/principal';
import {H2, H3} from "@origyn-sa/ui/components/atoms/H";
import HR from "@origyn-sa/ui/components/atoms/HR";
import Grid from "@origyn-sa/ui/components/atoms/Grid";
import Card from "@origyn-sa/ui/components/atoms/Card";
import {AuthContext} from "../../hooks/auth";
import useBalance from "../../hooks/balance";
import Flex from "@origyn-sa/ui/components/atoms/Flex/index";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CopyIcon from "@material-ui/icons/FileCopy";
import makeBlockie from "ethereum-blockies-base64";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {formatE8S} from "../../utils";
import Button from "@origyn-sa/ui/components/molecules/Button/index";
import {timeConverter} from "../../utils/dateTime";
import {getAccountId} from "../../utils/principalToAccountID";
import {copyToClipboard} from "../../utils";

import CopyButton from "../../components/CopyButton";
import WalletInfo from "../../components/WalletInfo";

const LongString = styled(H3)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Vesting = () => {
  const {logIn, loggedIn, walletType, account, logOut, principal, isLoading} = useContext(AuthContext);
  const {walletBalance, stakedTokens} = useBalance();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && !loggedIn) {
      history.push("/login");
    }
  }, [loggedIn, isLoading])

  return (
    <div>
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Vesting
      </H2>
      <Card
        column
        padding={2}
      >
        {
          stakedTokens?.length > 0 ? stakedTokens.map((token) => (
            <div>
              <Flex>
                <H2>{token.quantity.toString()}</H2>
                <H3 color="success">{token.unlockedQuantity.toString()}</H3>
              </Flex>
              <>
                {timeConverter(token.createdDate)} - {timeConverter(token.unlockedDate)}
              </>
            </div>
          )) : (
            <>
              <h2>You have no vesting history</h2>
              <br/>
            </>
          )
        }
      </Card>
    </div>
  );
};

export default Vesting;
