import React, { useContext } from "react";
import styled from "styled-components";
import Flex from "@origyn-sa/ui/components/atoms/Flex";
import Button from "@origyn-sa/ui/components/molecules/Button";
import { H2, H3 } from "@origyn-sa/ui/components/atoms/H";
import { AuthContext } from "../hooks/auth";
import useBalance from "../hooks/balance";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";

import makeBlockie from "ethereum-blockies-base64";
import { formatE8S } from "../utils";
import { getAccountId } from "../utils/principalToAccountID";

const LongString = styled(H3)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const WalletInfo = () => {
  const { logIn, loggedIn, walletType, principal, account, logOut } =
    useContext(AuthContext);
  const { walletBalance, tokens } = useBalance();

  console.log({ walletBalance, account });
  if (!principal) return <>Loading...</>;

  return (
    <List>
      <ListSubheader>
        <Flex align='center' justify='center' gap={2}>
          <img height={36} src={`/images/${walletType}-logo.png`} alt='' />
          <H2>
            {walletType === "ii"
              ? "Internet Identity"
              : walletType?.toUpperCase()}
          </H2>
        </Flex>
      </ListSubheader>
      <br />
      {/* <ListItem>
        <ListItemAvatar>
          <Avatar>
            <img
              style={{ width: "100%", height: "100%" }}
              src={makeBlockie(getAccountId(principal))}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
          // primary={<>{account?.name}</>}
          secondary={
            <>
              <div>ID: {getAccountId(principal).substr(0, 20) + "..."}</div>
              <div>
                Balance: {(walletBalance && formatE8S(walletBalance)) || "..."}{" "}
                OGY
              </div>
            </>
          }
        />
      </ListItem>
      <ListItem>
        <Typography
          style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
        >
          {walletBalance !== undefined
            ? formatE8S(walletBalance) + " OGY"
            : "Loading..."}
        </Typography>
      </ListItem> */}

      <ListItem>
        <Button onClick={logOut} style={{ width: "100%" }}>
          Logout
        </Button>
      </ListItem>
    </List>
  );
};

export default WalletInfo;
