import { AuthClient } from "@dfinity/auth-client";
import {Actor, HttpAgent} from "@dfinity/agent";
import ledgerIDL from "../../utils/candid/ledger.did.js";
import { idlFactory } from "../../utils/candid/airdrop/airdrop.did.js";
import idlGovernance  from "../../utils/candid/ogy_governance_canister.did.js";
import idlVesting  from "../../utils/candid/ogy_vesting_canister.did.js";
import {OGY_LEDGER_CANISTER_ID} from "../../utils/ledger";

const useInternetIdentity = () => {
  const connectInternetIdentity = async () => {
    const authClient = await AuthClient.create();
    console.log(authClient);
    if (await authClient.isAuthenticated()) {
      console.log("already authenticated ii");

      // const actor = await getIdentityActor(authClient);
      const identity = authClient.getIdentity();
      const principal = identity.getPrincipal();

      const airdropAgent = new HttpAgent({
        identity,
        host: "https://boundary.ic0.app/",
      });
      
      const airdropActor = Actor.createActor(idlFactory, {
        agent: airdropAgent,
        canisterId: process.env.AIRDROP_CANISTER_ID || "",
      });
      const governanceActor = Actor.createActor(idlGovernance as any, {
        agent: airdropAgent,
        canisterId: process.env.GOVERNANCE_CANISTER_ID || "",
      });
      const vestingActor = Actor.createActor(idlVesting as any, {
        agent: airdropAgent,
        canisterId: process.env.VESTING_CANISTER_ID || "",
      });
      const actor = Actor.createActor(ledgerIDL as any, {
        agent: airdropAgent,
        canisterId: OGY_LEDGER_CANISTER_ID || "",
      });
      window.localStorage.setItem("loggedIn", "ii");
      return {
        authorized: true,
        identity,
        airdropActor,
        governanceActor,
        vestingActor,
        principal,
        actor
      };
    } else {
      console.log("new login ii");
      await authClient.login({
        identityProvider: "https://identity.ic0.app/#authorize",
        onSuccess: async () => {
          const identity = authClient.getIdentity();
          const principal = identity.getPrincipal();
          console.log("onSuccess", identity, principal);
          window.localStorage.setItem("loggedIn", "ii");
          window.location.reload();
        },
      });
      return {
        authorized: false,
      };
    }
  };
  return connectInternetIdentity;
};

export default useInternetIdentity;
