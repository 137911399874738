export default ({ IDL }) => {
  const VarType = IDL.Rec();
  const StakedTokensRewardsEntry = IDL.Record({
    date: IDL.Int,
    amount: IDL.Nat64,
  });
  const StakedTokensStable__1 = IDL.Record({
    redeemedRewards: IDL.Vec(StakedTokensRewardsEntry),
    votes: IDL.Vec(IDL.Tuple(IDL.Int, IDL.Bool)),
    endLockTime: IDL.Int,
    createdDate: IDL.Int,
    unlockedQuantity: IDL.Nat64,
    last_classification_time: IDL.Int,
    unlockedDate: IDL.Int,
    rewards: IDL.Vec(StakedTokensRewardsEntry),
    quantity: IDL.Nat64,
    last_vote: IDL.Int,
    dissolving: IDL.Bool,
    follow: IDL.Opt(IDL.Tuple(IDL.Principal, IDL.Nat)),
    classification: IDL.Float64,
  });
  const GovProposalType = IDL.Variant({
    CanisterOversight: IDL.Principal,
    SetXDRRatio: IDL.Float64,
    Motion: IDL.Record({ url: IDL.Text, text: IDL.Text }),
  });
  const GovProposalStable__1 = IDL.Record({
    id: IDL.Int,
    result: IDL.Opt(
      IDL.Variant({
        Failed: IDL.Null,
        Passed: IDL.Null,
        NoQuorum: IDL.Null,
      })
    ),
    expires: IDL.Int,
    risked_ogy: IDL.Nat64,
    command_type: GovProposalType,
    for_votes: IDL.Nat64,
    proposer: IDL.Tuple(IDL.Principal, IDL.Nat),
    voting_power: IDL.Nat64,
    against_votes: IDL.Nat64,
  });
  const GovernanceBackupChunk = IDL.Record({
    staked: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, StakedTokensStable__1)),
    vesting: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, StakedTokensStable__1)),
    failed_mint: IDL.Vec(IDL.Tuple(IDL.Int, IDL.Nat64)),
    failed_send: IDL.Vec(
      IDL.Tuple(IDL.Int, IDL.Principal, IDL.Nat64, IDL.Nat64, IDL.Text)
    ),
    unstaked_balance: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat64)),
    redeemed_blocks: IDL.Vec(IDL.Nat64),
    proposals: IDL.Vec(IDL.Tuple(IDL.Int, GovProposalStable__1)),
  });
  VarType.fill(
    IDL.Record({
      key: IDL.Text,
      val: IDL.Variant({
        Int: IDL.Int,
        Nat: IDL.Nat,
        Opt: IDL.Opt(VarType),
        Nat32: IDL.Nat32,
        Nat64: IDL.Nat64,
        Blob: IDL.Vec(IDL.Nat8),
        Bool: IDL.Bool,
        Nat8: IDL.Nat8,
        Text: IDL.Text,
        Float: IDL.Float64,
        Variant: IDL.Tuple(IDL.Text, IDL.Opt(VarType)),
        Principal: IDL.Principal,
        Class: IDL.Vec(VarType),
      }),
    })
  );
  const LogEntry = IDL.Record({
    data: IDL.Vec(VarType),
    event: IDL.Text,
    timestamp: IDL.Int,
    caller: IDL.Opt(IDL.Principal),
  });
  const GovProposalStable = IDL.Record({
    id: IDL.Int,
    result: IDL.Opt(
      IDL.Variant({
        Failed: IDL.Null,
        Passed: IDL.Null,
        NoQuorum: IDL.Null,
      })
    ),
    expires: IDL.Int,
    risked_ogy: IDL.Nat64,
    command_type: GovProposalType,
    for_votes: IDL.Nat64,
    proposer: IDL.Tuple(IDL.Principal, IDL.Nat),
    voting_power: IDL.Nat64,
    against_votes: IDL.Nat64,
  });
  const Balances = IDL.Record({
    staked: IDL.Vec(IDL.Tuple(IDL.Nat, StakedTokensStable__1)),
    unstaked: IDL.Nat64,
    vesting: IDL.Vec(IDL.Tuple(IDL.Nat, StakedTokensStable__1)),
  });
  const Metrics = IDL.Record({
    total_claimable_rewards: IDL.Nat64,
    ogy_fee: IDL.Nat64,
    ogy_to_xdr: IDL.Float64,
    inflation_rate: IDL.Float64,
    total_staked: IDL.Nat64,
    last_reward_time: IDL.Int,
    last_classification_time: IDL.Int,
    total_unclaimed_rewards: IDL.Nat64,
    total_in_governance: IDL.Nat64,
    total_failed_proposal_forfeit: IDL.Nat64,
    last_inflation_rate_update: IDL.Int,
  });
  const ProposalFilter = IDL.Variant({ open: IDL.Null });
  const StakedTokensStable = IDL.Record({
    redeemedRewards: IDL.Vec(StakedTokensRewardsEntry),
    votes: IDL.Vec(IDL.Tuple(IDL.Int, IDL.Bool)),
    endLockTime: IDL.Int,
    createdDate: IDL.Int,
    unlockedQuantity: IDL.Nat64,
    last_classification_time: IDL.Int,
    unlockedDate: IDL.Int,
    rewards: IDL.Vec(StakedTokensRewardsEntry),
    quantity: IDL.Nat64,
    last_vote: IDL.Int,
    dissolving: IDL.Bool,
    follow: IDL.Opt(IDL.Tuple(IDL.Principal, IDL.Nat)),
    classification: IDL.Float64,
  });
  const StakeCommand = IDL.Record({
    stake_type: IDL.Variant({ staked: IDL.Null, vesting: IDL.Null }),
    command: IDL.Variant({
      updateDissolve: IDL.Bool,
      vote: IDL.Record({ vote: IDL.Bool, proposal: IDL.Int }),
      updateEndLockTime: IDL.Int,
      proposal: IDL.Record({
        command_type: GovProposalType,
        expires_length: IDL.Int,
      }),
      follow: IDL.Opt(IDL.Tuple(IDL.Principal, IDL.Nat)),
    }),
    index: IDL.Nat,
  });
  const ManageStakeResponse = IDL.Variant({
    Result: IDL.Bool,
    Proposal: IDL.Int,
  });
  const TimeMode = IDL.Variant({ test: IDL.Null, standard: IDL.Null });
  const UpdateInflationResponse = IDL.Record({
    inflation_rate: IDL.Float64,
    last_inflation_rate_update: IDL.Int,
  });
  const AccountIdentifier = IDL.Text;
  const anon_class_72_1 = IDL.Service({
    back_up: IDL.Func(
      [IDL.Nat],
      [
        IDL.Variant({
          eof: GovernanceBackupChunk,
          data: GovernanceBackupChunk,
        }),
      ],
      ["query"]
    ),
    change_owner: IDL.Func([IDL.Principal, IDL.Principal], [IDL.Bool], []),
    classify_staking_rewards: IDL.Func([], [IDL.Int], []),
    current_log: IDL.Func([], [IDL.Vec(LogEntry)], ["query"]),
    finalize_vote: IDL.Func([IDL.Int], [GovProposalStable], []),
    getAccountID: IDL.Func([], [IDL.Text], ["query"]),
    get_balances: IDL.Func([], [Balances], ["query"]),
    get_ledger_canister_id: IDL.Func([], [IDL.Principal], ["query"]),
    get_metrics: IDL.Func([], [Metrics], ["query"]),
    get_minting_canister_id: IDL.Func([], [IDL.Principal], ["query"]),
    get_proposals: IDL.Func(
      [IDL.Nat, IDL.Nat, IDL.Opt(ProposalFilter)],
      [IDL.Vec(GovProposalStable)],
      ["query"]
    ),
    get_staked_tokens_by_principal: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(StakedTokensStable)],
      ["query"]
    ),
    get_staked_tokens_principals: IDL.Func(
      [],
      [IDL.Vec(IDL.Principal)],
      ["query"]
    ),
    get_vested_tokens_principals: IDL.Func(
      [],
      [IDL.Vec(IDL.Principal)],
      ["query"]
    ),
    get_vesting_canister_id: IDL.Func([], [IDL.Principal], ["query"]),
    get_vesting_tokens_by_principal: IDL.Func(
      [IDL.Principal],
      [IDL.Vec(StakedTokensStable)],
      ["query"]
    ),
    harvest_log: IDL.Func([IDL.Nat], [IDL.Vec(IDL.Vec(LogEntry))], []),
    initialize: IDL.Func([IDL.Principal], [], []),
    kill_vesting: IDL.Func(
      [IDL.Principal, IDL.Vec(IDL.Nat)],
      [IDL.Record({ rewards: IDL.Nat64, quantity: IDL.Nat64 })],
      []
    ),
    log_history_page: IDL.Func([IDL.Nat], [IDL.Vec(LogEntry)], ["query"]),
    log_history_page_chunk: IDL.Func(
      [IDL.Nat, IDL.Nat, IDL.Nat],
      [IDL.Vec(LogEntry)],
      ["query"]
    ),
    log_history_size: IDL.Func([], [IDL.Nat], ["query"]),
    manage_stake: IDL.Func([StakeCommand], [ManageStakeResponse], []),
    nuke_log: IDL.Func([], [], []),
    predict_vote: IDL.Func([IDL.Int], [GovProposalStable], ["query"]),
    redeem_deposit: IDL.Func(
      [IDL.Principal, IDL.Opt(IDL.Vec(IDL.Nat8)), IDL.Nat64],
      [IDL.Nat64],
      []
    ),
    redeem_rewards: IDL.Func([IDL.Nat], [IDL.Nat64], []),
    retry_mint_and_clear: IDL.Func([], [IDL.Nat64], []),
    run_staking_rewards: IDL.Func([], [IDL.Nat64], []),
    set_admin: IDL.Func([IDL.Principal], [], []),
    set_data_harvester_id: IDL.Func([IDL.Principal, IDL.Nat], [], []),
    set_default_follow: IDL.Func(
      [IDL.Opt(IDL.Tuple(IDL.Principal, IDL.Nat))],
      [],
      []
    ),
    set_halt: IDL.Func([IDL.Bool], [], []),
    set_ledger_canister_id: IDL.Func([IDL.Principal], [], []),
    set_log_harvester_id: IDL.Func([IDL.Principal], [], []),
    set_maintenance: IDL.Func([IDL.Principal], [], []),
    set_minting_canister_id: IDL.Func([IDL.Principal], [], []),
    set_ogy_fee: IDL.Func([IDL.Nat64], [], []),
    set_test_time: IDL.Func([IDL.Int], [], []),
    set_time_mode: IDL.Func([TimeMode], [], []),
    set_vesting_canister_id: IDL.Func([IDL.Principal], [], []),
    stake_tokens: IDL.Func([IDL.Nat64, IDL.Int], [IDL.Nat], []),
    staked_tokens_seed: IDL.Func(
      [IDL.Principal, StakedTokensStable],
      [IDL.Nat],
      []
    ),
    unstake_tokens: IDL.Func([IDL.Nat], [IDL.Nat64], []),
    update_inflation_rate: IDL.Func([], [UpdateInflationResponse], []),
    vest_stake: IDL.Func([IDL.Principal, IDL.Nat], [IDL.Nat], []),
    wallet_receive: IDL.Func([], [IDL.Nat], []),
    wallet_send: IDL.Func([IDL.Nat, IDL.Principal], [IDL.Nat], []),
    withdraw_unlocked_tokens: IDL.Func(
      [AccountIdentifier, IDL.Nat64],
      [IDL.Nat64],
      []
    ),
  });
  return anon_class_72_1;
};
export const init = ({ IDL }) => {
  return [];
};
