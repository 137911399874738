import { Actor, HttpAgent } from "@dfinity/agent";
import ledgerIDL from "./candid/ledger.did.js";

// mainnet
export const OGY_LEDGER_CANISTER_ID = process.env.LEDGER_CANISTER_ID || "";

export const getLedgerActor = (identity) => {
  const agent = new HttpAgent({
    identity,
    host: "https://boundary.ic0.app/",
  });
  return Actor.createActor(ledgerIDL, {
    agent,
    canisterId: OGY_LEDGER_CANISTER_ID,
  });
};
