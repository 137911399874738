import { Actor, HttpAgent } from "@dfinity/agent";
import govFactory from "./candid/ogy_governance_canister.did.js";
import vestingFactory from "./candid/ogy_vesting_canister.did.js";

export const GOVERNANCE_CANISTER_ID = process.env.GOVERNANCE_CANISTER_ID || "";
export const VESTING_CANISTER_ID = process.env.VESTING_CANISTER_ID || "";

export const getVestingActor = (identity) => {
  const agent = new HttpAgent({
    identity,
    host: "https://boundary.ic0.app/",
  });
  return Actor.createActor(vestingFactory as any, {
    agent,
    canisterId: VESTING_CANISTER_ID,
  });
};

export const getGovernanceActor = (identity) => {
  const agent = new HttpAgent({
    identity,
    host: "https://boundary.ic0.app/",
  });
  return Actor.createActor(govFactory as any, {
    agent,
    canisterId: GOVERNANCE_CANISTER_ID,
  });
};
