export default ({ IDL }) => {
  const VarType = IDL.Rec();
  const InvestorSchedule__1 = IDL.Record({
    vestingPrincipal: IDL.Principal,
    endDate: IDL.Int,
    distributedAmount: IDL.Nat64,
    quantity: IDL.Nat64,
    vestingIndex: IDL.Nat,
    startDate: IDL.Int,
  });
  const VestingBackupChunk = IDL.Record({
    failed_send: IDL.Vec(IDL.Nat64),
    unclaimed: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, InvestorSchedule__1)),
    vested: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, InvestorSchedule__1)),
    unvested: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, InvestorSchedule__1)),
    vested_amount: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat64)),
  });
  VarType.fill(
    IDL.Record({
      key: IDL.Text,
      val: IDL.Variant({
        Int: IDL.Int,
        Nat: IDL.Nat,
        Opt: IDL.Opt(VarType),
        Nat32: IDL.Nat32,
        Nat64: IDL.Nat64,
        Blob: IDL.Vec(IDL.Nat8),
        Bool: IDL.Bool,
        Nat8: IDL.Nat8,
        Text: IDL.Text,
        Float: IDL.Float64,
        Variant: IDL.Tuple(IDL.Text, IDL.Opt(VarType)),
        Principal: IDL.Principal,
        Class: IDL.Vec(VarType),
      }),
    })
  );
  const LogEntry = IDL.Record({
    data: IDL.Vec(VarType),
    event: IDL.Text,
    timestamp: IDL.Int,
    caller: IDL.Opt(IDL.Principal),
  });
  const Balances = IDL.Record({
    unclaimed: IDL.Vec(IDL.Tuple(IDL.Nat, InvestorSchedule__1)),
    vested: IDL.Vec(IDL.Tuple(IDL.Nat, InvestorSchedule__1)),
    unvested: IDL.Vec(IDL.Tuple(IDL.Nat, InvestorSchedule__1)),
    vestable: IDL.Vec(IDL.Tuple(IDL.Nat, InvestorSchedule__1)),
  });
  const AdminBalances = IDL.Record({
    unclaimed: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, InvestorSchedule__1)),
    vested: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, InvestorSchedule__1)),
    unvested: IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat, InvestorSchedule__1)),
  });
  const TimeMode = IDL.Variant({ test: IDL.Null, standard: IDL.Null });
  const InvestorSchedule = IDL.Record({
    vestingPrincipal: IDL.Principal,
    endDate: IDL.Int,
    distributedAmount: IDL.Nat64,
    quantity: IDL.Nat64,
    vestingIndex: IDL.Nat,
    startDate: IDL.Int,
  });
  const anon_class_50_1 = IDL.Service({
    back_up: IDL.Func(
      [IDL.Nat],
      [
        IDL.Variant({
          eof: VestingBackupChunk,
          data: VestingBackupChunk,
        }),
      ],
      ["query"]
    ),
    change_owner: IDL.Func([IDL.Principal, IDL.Principal], [IDL.Bool], []),
    current_log: IDL.Func([], [IDL.Vec(LogEntry)], ["query"]),
    get_balances: IDL.Func([], [Balances], ["query"]),
    get_governance_canister_id: IDL.Func([], [IDL.Principal], ["query"]),
    get_last_vesting_time: IDL.Func([], [IDL.Int], ["query"]),
    get_ledger_canister_id: IDL.Func([], [IDL.Principal], ["query"]),
    get_vested_investors: IDL.Func([], [AdminBalances], ["query"]),
    harvest_log: IDL.Func([IDL.Nat], [IDL.Vec(IDL.Vec(LogEntry))], []),
    initialize: IDL.Func([IDL.Principal], [], []),
    kill_vesting: IDL.Func([IDL.Principal], [IDL.Vec(IDL.Nat)], []),
    log_history_page: IDL.Func([IDL.Nat], [IDL.Vec(LogEntry)], ["query"]),
    log_history_page_chunk: IDL.Func(
      [IDL.Nat, IDL.Nat, IDL.Nat],
      [IDL.Vec(LogEntry)],
      ["query"]
    ),
    log_history_size: IDL.Func([], [IDL.Nat], ["query"]),
    nuke_log: IDL.Func([], [], []),
    run_vesting: IDL.Func([], [], []),
    set_admin: IDL.Func([IDL.Principal], [], []),
    set_data_harvester_id: IDL.Func([IDL.Principal, IDL.Nat], [], []),
    set_governance_canister_id: IDL.Func([IDL.Principal], [], []),
    set_halt: IDL.Func([IDL.Bool], [], []),
    set_ledger_canister_id: IDL.Func([IDL.Principal], [], []),
    set_log_harvester_id: IDL.Func([IDL.Principal], [], []),
    set_maintenance: IDL.Func([IDL.Principal], [], []),
    set_ogy_fee: IDL.Func([IDL.Nat64], [], []),
    set_test_time: IDL.Func([IDL.Int], [], []),
    set_time_mode: IDL.Func([TimeMode], [], []),
    set_vested_investors: IDL.Func([IDL.Vec(InvestorSchedule)], [], []),
    stake_claimable_tokens: IDL.Func(
      [IDL.Nat, IDL.Opt(IDL.Principal)],
      [IDL.Nat],
      []
    ),
    vest_tokens: IDL.Func([IDL.Nat], [IDL.Nat], []),
    wallet_receive: IDL.Func([], [IDL.Nat], []),
    wallet_send: IDL.Func([IDL.Nat, IDL.Principal], [IDL.Nat], []),
  });
  return anon_class_50_1;
};
export const init = ({ IDL }) => {
  return [];
};
