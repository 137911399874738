import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Principal } from "@dfinity/principal";
import { H2, H3, H4, H5 } from "@origyn-sa/ui/components/atoms/H";
import HR from "@origyn-sa/ui/components/atoms/HR";
import Card from "@origyn-sa/ui/components/atoms/Card";
import { AuthContext } from "../../hooks/auth";
import useBalance from "../../hooks/balance";
import Flex from "@origyn-sa/ui/components/atoms/Flex/index";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CopyIcon from "@material-ui/icons/FileCopy";
import makeBlockie from "ethereum-blockies-base64";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { formatE8S } from "../../utils";
import Button from "@origyn-sa/ui/components/molecules/Button/index";
import Select from "@origyn-sa/ui/components/molecules/Select/index";
import { timeConverter } from "../../utils/dateTime";
import { getAccountId } from "../../utils/principalToAccountID";
import { copyToClipboard } from "../../utils";
import Grid from "@material-ui/core/Grid";
import Table from "@origyn-sa/ui/components/molecules/Table";
import TableCell from "@origyn-sa/ui/components/molecules/Table/TableCell";

import CopyButton from "../../components/CopyButton";
import WalletInfo from "../../components/WalletInfo";
import { getGovernanceActor, getVestingActor } from "../../utils/actors";
import governanceIDL from "../../utils/candid/ogy_governance_canister.did.js";
import vestingIDL from "../../utils/candid/ogy_vesting_canister.did.js";
import { StoicIdentity } from "ic-stoic-identity";
import { AuthClient } from "@dfinity/auth-client";
import JSONbig from "json-bigint";
import Modal from "@origyn-sa/ui/components/molecules/Modal/index";
import Input from "@origyn-sa/ui/components/molecules/Input/index";
import { toast } from "@origyn-sa/ui/utils/toaster";
import {
  useSendOGY,
  useGovernanceDepositOGY,
  useGovernanceActor,
  useGovernanceWithdrawOGY,
  useVestingActor,
} from "../../hooks/send/sendOGY";
import { OGY_LEDGER_CANISTER_ID } from "../../utils/ledger";

const LongString = styled(H3)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Stakes = () => {
  const { logIn, loggedIn, walletType, account, logOut, principal, isLoading } =
    useContext(AuthContext);
  const { walletBalance } = useBalance();
  const history = useHistory();

  const [stakedTokens, setStakedTokens] = useState([]);
  const [unstakedOGY, setUnstakedOGY] = useState(0n);
  const [stakedVestingTokens, setStakedVestingTokens] = useState([]);

  const [stakedOGY, setStakedOGY] = useState(0n);
  const [sumVesting, setSumVesting] = useState(0n);

  const [vestingBalances, setVestingBalances] = useState<any>([]);

  const governanceDepositOGY = useGovernanceDepositOGY();
  const governanceWithdrawOGY = useGovernanceWithdrawOGY();
  const { _governanceActor } = useGovernanceActor();
  const { _vestingActor, stakeClaimableTokens } = useVestingActor();

  const [manageStakeOpen, setManageStakeOpen] = useState(false);
  const [currentManagedStake, setCurrentManagedStake] =
    useState<any>(undefined);
  const [createStakeOpen, setCreateStakeOpen] = useState(false);
  const [newStakeForm, setNewStakeForm] = useState<any>(false);

  console.log(`Stake page, principal: ${principal}`);

  const handleRedeem = (type, vestingObject) => {
    console.log({ type, vestingObject });
    alert("TODO handleRedeem : ");
  };

  const handleStakeToken = () => {
    console.log({ amount, FEE });
    setCreateStakeOpen(true);
  };

  const handleVest = async (vestingIndex) => {
    console.log({ vestingIndex });
    if (!principal) {
      console.error("not connected");
      return;
    }
    toast.info("Vesting...");
    try {
      const vestActor = await _vestingActor();
      if (!vestActor) {
        throw new Error("not connected");
      }

      await vestActor.vest_tokens(vestingIndex);
      toast.success("Vested! reloading");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e) {
      console.error({ e });
      toast.error("Error");
    }
  };

  const handleManageStake = (type, stakeObject) => {
    console.log({ stakeObject, type });
    setCurrentManagedStake({
      type,
      stakeObject,
    });
    setManageStakeOpen(true);
  };

  const handleManageStakedClose = () => {
    setCurrentManagedStake(undefined);
    setManageStakeOpen(false);
  };

  const handleUnstake = async (stakedItemIndex) => {
    toast.info("Unstaking: " + stakedItemIndex);

    try {
      const governanceActor = await _governanceActor();
      if (!governanceActor) throw new Error("governance actor error");
      const index = await governanceActor.unstake_tokens(stakedItemIndex);
      console.log("unstaked with index " + index);
      toast.success("Unstaked");
    } catch (e) {
      console.error({ e });
      toast.error("Error: " + e);
    }
  };

  const handleCreateStakeClose = () => {
    setNewStakeForm(undefined);
    setCreateStakeOpen(false);
  };

  const handleCreateStakeSave = async (nbYear) => {
    const nowNanos = BigInt(Date.now()) * 10n ** 6n;
    const oneDayNanos = 3600n * 24n * 10n ** 9n;
    const oneYearNanos = BigInt(nbYear) * oneDayNanos * 365n;
    console.log({ nowNanos, oneYearNanos });
    const endLock =
      nowNanos + // to nanos
      oneYearNanos +
      oneDayNanos; // offset;
    console.log({ nbYear, amount });
    const _amounte8s = BigInt(amount * 10 ** 8);
    toast.info("Staking...");
    try {
      const governanceActor = await _governanceActor();
      if (!governanceActor) throw new Error("governance actor error");
      const index = await governanceActor.stake_tokens(_amounte8s, endLock);
      console.log("staked with index " + index);
      toast.success("Staked");
    } catch (e) {
      console.error({ e });
      toast.error("Error: " + e);
    }
  };

  const handleClaim = async () => {
    const unclaimedVestings = vestingBalances?.unclaimed || [];
    try {
      toast.info("Claiming...");
      await stakeClaimableTokens(unclaimedVestings?.map((v) => v[0]));
      toast.success("Claimed. reloading.");
      setTimeout(() => window.location.reload(), 2000);
    } catch (e) {
      console.error({ e });
      toast.error("Error... " + e);
    }
  };

  useEffect(() => {
    const stakedTokensSum = stakedTokens.reduce((acc: bigint, token: any) => {
      console.log({ acc, token });
      return acc + token[1].quantity;
    }, 0n);
    setStakedOGY(stakedTokensSum);
    console.log("stakedVestingTokens");
    console.log({ stakedVestingTokens });
    const _sumVesting = stakedVestingTokens.reduce(
      (acc: bigint, token: any) => {
        return acc + token[1].quantity;
      },
      0n
    );
    console.log(_sumVesting);
    setSumVesting(_sumVesting);
  }, [stakedTokens, stakedVestingTokens]);

  useEffect(() => {
    if (!isLoading && !loggedIn) {
      history.push("/login");
    }

    const getData = async () => {
      console.log("getData", loggedIn, principal);
      if (loggedIn) {
        if (!principal) {
          throw new Error("No principal");
        }
        switch (walletType) {
          case "ii": {
            const authClient = await AuthClient.create();
            console.log(authClient);
            const identity = authClient.getIdentity();
            const governanceActor = getGovernanceActor(identity);
            // TODO: fix type
            const balances: any = await governanceActor.get_balances();
            console.log({ msg: "balances ii", balances });
            setUnstakedOGY(balances.unstaked);
            setStakedTokens(balances.staked);
            setStakedVestingTokens(balances.vesting);

            const vestingActor = getVestingActor(identity);
            const balancesVestings: any = await vestingActor.get_balances();
            console.log("balances vestings", balancesVestings);
            setVestingBalances(balancesVestings);

            break;
          }
          case "stoic": {
            StoicIdentity.load()
              .then((identity) => {
                const governanceActor = getGovernanceActor(identity);
                governanceActor
                  .get_balances()
                  .then((balances) => {
                    console.log("balances", balances);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
            break;
          }
          case "plug":{
            if (!(await window.ic.plug.isConnected())) {
              const connected = await window.ic.plug.requestConnect({
                whitelist: [OGY_LEDGER_CANISTER_ID, process.env.AIRDROP_CANISTER_ID, process.env.GOVERNANCE_CANISTER_ID, process.env.VESTING_CANISTER_ID],
              });
              if (connected === "denied")
                throw new Error("Error with plug.requestConnect");
            }
        
            await window.ic.plug.createAgent({
              whitelist: [OGY_LEDGER_CANISTER_ID, process.env.AIRDROP_CANISTER_ID, process.env.GOVERNANCE_CANISTER_ID, process.env.VESTING_CANISTER_ID],
              host: "https://boundary.ic0.app",
            });

            const governanceActor = await window.ic.plug.createActor({
              canisterId: process.env.GOVERNANCE_CANISTER_ID,
              interfaceFactory: governanceIDL,
            });

            const vestingActor = await window.ic.plug.createActor({
              canisterId: process.env.VESTING_CANISTER_ID,
              interfaceFactory: vestingIDL,
            });
        
            const plugPrincipal = await window.ic.plug.agent.getPrincipal();

            const balances: any = await governanceActor.get_balances();
            console.log({ msg: "balances ii", balances });
            setUnstakedOGY(balances.unstaked);
            setStakedTokens(balances.staked);
            setStakedVestingTokens(balances.vesting);

            
            const balancesVestings: any = await vestingActor.get_balances();
            console.log("balances vestings", balancesVestings);
            setVestingBalances(balancesVestings);
            break;
          }
          default: {
            throw new Error(`no stake implement for ${walletType}`);
          }
        }
      }
    };
    getData();
  }, [loggedIn, isLoading]);

  console.log({ stakedVestingTokens });
  let dataStakedOGY: any[] = [];
  let dataStakedVestingsOGY: any[] = [];
  let dataVestingUnclaimed: any[] = [];
  let dataVestingVestable: any[] = [];
  let dataVestingVested: any[] = [];

  try {
    console.log("dataStakedVestingsOGY");
    dataStakedVestingsOGY.push(
      ...(stakedVestingTokens
        ?.filter((item) => {
          const vestingSchedule: any = item[1];

          // hide disbursed stakes
          if (
            vestingSchedule.unlockedDate > 0 ||
            vestingSchedule.unlockedQuantity > 0
          ) {
            return false;
          }
          return true;
        })
        ?.sort((a, b) => {
          const vestingScheduleA: any = a[1];
          const vestingScheduleB: any = b[1];
          //console.log("mark", vestingScheduleA, vestingScheduleB);
          //console.log(vestingScheduleA.endLockTime);
          //console.log(vestingScheduleB.endLockTime);
          //console.log(parseInt(vestingScheduleA.endLockTime));
          //console.log(parseInt(vestingScheduleB.endLockTime));
          //console.log(parseInt(vestingScheduleA.endLockTime) - parseInt(vestingScheduleB.endLockTime));
          //console.log(parseInt(vestingScheduleA.endDate - vestingScheduleB.endDate));

          return (
            parseInt(vestingScheduleA.endLockTime) -
            parseInt(vestingScheduleB.endLockTime)
          );
        })
        .map((item) => {
          const vestingSchedule: any = item[1];
          console.log({ vestingSchedule });
          const totalRewards = vestingSchedule?.rewards?.reduce(
            (acc: bigint, reward: any) => acc + reward.amount,
            0n
          );
          const redeemedRewards = vestingSchedule?.redeemedRewards?.reduce(
            (acc: bigint, reward: any) => acc + reward.amount,
            0n
          );
          const toRedeem = BigInt(totalRewards - redeemedRewards);
          return [
            item[0],
            timeConverter(vestingSchedule.createdDate),
            timeConverter(vestingSchedule.createdDate),
            timeConverter(vestingSchedule.endLockTime),
            formatE8S(vestingSchedule.quantity),
            vestingSchedule.dissolving,
            vestingSchedule.classification,
            formatE8S(totalRewards),
            formatE8S(redeemedRewards),
            formatE8S(toRedeem),
            <TableCell>
              {/* Can't redeem rewards on vesting stake */}
              {/* So no "Redeem button" */}

              <Button onClick={() => handleManageStake("vesting", item)}>
                Manage stake
              </Button>
            </TableCell>,
          ];
        }) || [])
    );
    console.log("end dataStakedVestingsOGY");
    console.log({ dataStakedVestingsOGY });
  } catch (e) {
    //debugger;
    console.error({ e });
  }

  try {
    console.log("dataVestingUnclaimed");
    dataVestingUnclaimed.push(
      ...(vestingBalances?.unclaimed?.map((item) => {
        console.log({ item });
        const vestingSchedule: any = item[1];
        return [
          item[0],
          timeConverter(vestingSchedule.startDate),
          timeConverter(vestingSchedule.startDate),
          timeConverter(vestingSchedule.endDate),
          formatE8S(vestingSchedule.quantity),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          <TableCell></TableCell>,
        ];
      }) || [])
    );
  } catch (e) {
    //debugger;
    console.error({ e });
  }

  try {
    console.log("dataStakedOGY");
    dataStakedOGY.push(
      ...(stakedTokens
        ?.sort((a, b) => {
          const vestingScheduleA: any = a[1];
          const vestingScheduleB: any = b[1];
          //console.log("mark", vestingScheduleA, vestingScheduleB);
          //console.log(vestingScheduleA.endLockTime);
          //console.log(vestingScheduleB.endLockTime);
          //console.log(parseInt(vestingScheduleA.endLockTime));
          //console.log(parseInt(vestingScheduleB.endLockTime));
          //console.log(parseInt(vestingScheduleA.endLockTime) - parseInt(vestingScheduleB.endLockTime));
          //console.log(parseInt(vestingScheduleA.endDatevestingScheduleB.endDate));

          return (
            parseInt(vestingScheduleA.endLockTime) -
            parseInt(vestingScheduleB.endLockTime)
          );
        })
        ?.map((item) => {
          console.log({ item });
          const vestingSchedule: any = item[1];
          const totalRewards = vestingSchedule.rewards?.reduce(
            (acc: bigint, reward: any) => acc + reward.amount,
            0n
          );
          const redeemedRewards = vestingSchedule.redeemedRewards.reduce(
            (acc: bigint, reward: any) => acc + reward.amount,
            0n
          );
          const toRedeem = BigInt(totalRewards - redeemedRewards);
          console.log({
            end: vestingSchedule.endLockTime,
            now: BigInt(Date.now() * 10 ** 6),
          });
          return [
            item[0],
            timeConverter(vestingSchedule.createdDate),
            timeConverter(vestingSchedule.createdDate),
            timeConverter(vestingSchedule.endLockTime),
            formatE8S(vestingSchedule.quantity),
            vestingSchedule.dissolving,
            vestingSchedule.classification,
            formatE8S(totalRewards),
            formatE8S(redeemedRewards),
            formatE8S(toRedeem),
            <TableCell>
              <Button onClick={() => handleManageStake("staked", item)}>
                Manage stake
              </Button>
              {toRedeem > 0 ? (
                <Button onClick={() => handleRedeem("staked", item)}>
                  Redeem
                </Button>
              ) : null}
              {vestingSchedule.endLockTime < BigInt(Date.now() * 10 ** 6) && (
                <Button onClick={() => handleUnstake(item[0])}>Unstake</Button>
              )}{" "}
            </TableCell>,
          ];
        }) || [])
    );
  } catch (e) {
    //debugger;
    console.error({ e });
  }

  try {
    console.log("dataVestingVestable");
    dataVestingVestable.push(
      ...(vestingBalances?.vestable
        ?.sort((a, b) => {
          const vestingScheduleA: any = a[1];
          const vestingScheduleB: any = b[1];
          //console.log("mark", vestingScheduleA, vestingScheduleB);
          //console.log(vestingScheduleA.endDate);
          //console.log(vestingScheduleB.endDate);
          //console.log(parseInt(vestingScheduleA.endDate));
          //console.log(parseInt(vestingScheduleB.endDate));
          //console.log(parseInt(vestingScheduleA.endDate) - parseInt(vestingScheduleB.endDate));
          //console.log(parseInt(vestingScheduleA.endDatevestingScheduleB.endDate));

          return (
            parseInt(vestingScheduleA.endDate) -
            parseInt(vestingScheduleB.endDate)
          );
        })
        ?.map((item) => {
          console.log({ item });
          const vestingSchedule: any = item[1];
          const totalRewards =
            vestingSchedule.rewards?.reduce(
              (acc: bigint, reward: any) => acc + reward.amount,
              0n
            ) || 0n;
          const redeemedRewards =
            vestingSchedule.redeemedRewards?.reduce(
              (acc: bigint, reward: any) => acc + reward.amount,
              0n
            ) || 0n;
          const toRedeem = BigInt(totalRewards - redeemedRewards);
          return [
            item[0],
            timeConverter(vestingSchedule.startDate),
            timeConverter(vestingSchedule.startDate),
            timeConverter(vestingSchedule.endDate),
            formatE8S(vestingSchedule.quantity),
            vestingSchedule.dissolving,
            vestingSchedule.classification,
            formatE8S(totalRewards),
            formatE8S(redeemedRewards),
            formatE8S(toRedeem),
            <TableCell>
              <Button onClick={() => handleVest(item[0])}>Vest Stake</Button>
            </TableCell>,
          ];
        }) || [])
    );
  } catch (e) {
    //debugger;
    console.error({ e });
  }
  try {
    console.log("dataVestingVested");
    dataVestingVested.push(
      ...(vestingBalances?.vested
        ?.sort((a, b) => {
          const vestingScheduleA: any = a[1];
          const vestingScheduleB: any = b[1];
          //console.log("mark", vestingScheduleA, vestingScheduleB);
          //console.log(vestingScheduleA.endDate);
          //console.log(vestingScheduleB.endDate);
          //console.log(parseInt(vestingScheduleA.endDate));
          //console.log(parseInt(vestingScheduleB.endDate));
          //console.log(parseInt(vestingScheduleA.endDate) - parseInt(vestingScheduleB.endDate));
          //console.log(parseInt(vestingScheduleA.endDatevestingScheduleB.endDate));

          return (
            parseInt(vestingScheduleA.endDate) -
            parseInt(vestingScheduleB.endDate)
          );
        })
        ?.map((item) => {
          const vestingSchedule: any = item[1];
          console.log({ vestingSchedule });
          const totalRewards =
            vestingSchedule.rewards?.reduce(
              (acc: bigint, reward: any) => acc + reward.amount,
              0n
            ) || 0n;
          const redeemedRewards =
            vestingSchedule.redeemedRewards?.reduce(
              (acc: bigint, reward: any) => acc + reward.amount,
              0n
            ) || 0n;
          const toRedeem = BigInt(totalRewards - redeemedRewards);
          return [
            item[0],
            timeConverter(vestingSchedule.startDate),
            timeConverter(vestingSchedule.startDate),
            timeConverter(vestingSchedule.endDate),
            formatE8S(vestingSchedule.quantity),
            vestingSchedule.dissolving,
            vestingSchedule.classification,
            formatE8S(totalRewards),
            formatE8S(vestingSchedule.distributedAmount),
            formatE8S(toRedeem),
            <TableCell>
              {toRedeem > 0 ? (
                <Button onClick={() => handleRedeem("vested", item)}>
                  Redeem
                </Button>
              ) : null}
            </TableCell>,
          ];
        }) || [])
    );
  } catch (e) {
    //debugger;
    console.error({ e });
  }
  // const dataVestingUnclaimed = [];

  const sendOGY = useSendOGY();
  const [sendOpen, setSendOpen] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState(0);
  const [memo, setMemo] = useState(0);

  const FEE = 0.002;
  const handleWithdraw = () => setSendOpen(true);
  const handleOnCloseModal = () => {
    console.log("handleOnCloseModal");
    setSendOpen(false);
  };
  const handleSetMemo = (e) => {
    setMemo(e.target.value);
  };
  const handleWithdrawOGY = async () => {
    console.log("handleWithdrawOGY", { recipient, amount });
    if (recipient.length != 64) {
      return toast.error("Invalid recipient length (64)");
    }
    if (amount < 0) {
      return toast.error("Amount lower than zero");
    }
    const e8s = 10 ** 8;
    const amount_e8s = BigInt(Math.round(amount * e8s));
    const totalMinus = amount_e8s;
    console.log({ amount_e8s });
    // check recipient validity
    // check BigInt(amount+fee) > walletBalance
    if ((unstakedOGY || 0n) < totalMinus) {
      return toast.error("Balance is insufficient...");
    }

    try {
      toast.info("Transfer in progress...");
      const blockHeight = await governanceWithdrawOGY(amount_e8s, recipient);
      toast.success(
        `Withdrawal successful ${blockHeight} - reloading the page`
      );
      setSendOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (e) {
      console.error({ e });
      toast.error(`Error while withdrawing OGY:  ${e}`);
    }
  };

  const handleSetAmount = (e) => {
    setAmount(e.target.value);
  };
  const handleSetRecipient = (e) => {
    setRecipient(e.target.value);
  };

  const [depositOpen, setDepositOpen] = useState(false);

  const handleDepositOGY = async () => {
    console.log("handleDepositOGY", { amount });
    // if (recipient.length != 64) {
    //   return toast.error("Invalid recipient length (64)");
    // }
    if (amount < 0) {
      return toast.error("Amount lower than zero");
    }
    let memo_bigint = 0n;
    const e8s = 10 ** 8;
    const amount_e8s = BigInt(Math.round(amount * e8s));
    const fee_e8s = BigInt(Math.round(FEE * e8s));
    const totalMinus = amount_e8s + fee_e8s;
    console.log({ amount_e8s, memo_bigint, fee_e8s });
    // check recipient validity
    // check BigInt(amount+fee) > walletBalance
    if ((walletBalance || 0n) < totalMinus) {
      return toast.error("Balance is insufficient...");
    }

    try {
      toast.info("Deposit in progress...");
      // Transfer
      const res = await governanceDepositOGY(amount_e8s, fee_e8s);
      // call get_block
      console.log({ res });
      toast.success(`Deposit successful - reloading the page`);
      setSendOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (e) {
      console.error({ e });
      toast.error(`Error while depositing OGY:  ${e}`);
    }
  };

  const handleExtendStake = async (type, nbYear) => {
    toast.info("Extending stake...");
    console.log({ currentManagedStake });
    try {
      const governanceActor = await _governanceActor();
      if (!governanceActor)
        throw new Error("vesting actor failed to instantiate");
      const ONE_YEAR_NANOS = 3600n * 24n * 365n * 10n ** 9n;
      const res = await governanceActor.manage_stake({
        stake_type: {
          [type]: null,
        },
        command: {
          updateEndLockTime:
            currentManagedStake.stakeObject[1].endLockTime +
            BigInt(nbYear) * ONE_YEAR_NANOS +
            600n * 10n ** 9n, // +1h offset,
        },
        index: currentManagedStake.stakeObject[0],
      });
      if (res) {
        toast.success("Updated!");
      } else {
        toast.error("Failed...");
      }
      // const StakeCommand = IDL.Record({
      //   stake_type: IDL.Variant({ staked: IDL.Null, vesting: IDL.Null }),
      //   command: IDL.Variant({
      //     updateDissolve: IDL.Bool,
      //     updateEndLockTime: IDL.Int,
      //   }),
      //   index: IDL.Nat,
      // });
    } catch (e) {
      console.error({ e });
      toast.error("Failed");
    }
  };

  const handleUpdateDissolve = async (type, bool) => {
    toast.info("Update dissolve...");
    console.log({ currentManagedStake });
    try {
      const governanceActor = await _governanceActor();
      if (!governanceActor) throw new Error("gov actor failed to instantiate");
      const res = await governanceActor.manage_stake({
        stake_type: {
          [type]: null,
        },
        command: {
          updateDissolve: bool,
        },
        index: currentManagedStake.stakeObject[0],
      });
      if (res) {
        toast.success("Updated!");
      } else {
        toast.error("Failed...");
      }
      // const StakeCommand = IDL.Record({
      //   stake_type: IDL.Variant({ staked: IDL.Null, vesting: IDL.Null }),
      //   command: IDL.Variant({
      //     updateDissolve: IDL.Bool,
      //     updateEndLockTime: IDL.Int,
      //   }),
      //   index: IDL.Nat,
      // });
    } catch (e) {
      console.error({ e });
      toast.error("Failed");
    }
  };

  return (
    <div>
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Governance Canister Balances
      </H2>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container>
            Unstaked OGY
            <br />
            {formatE8S(unstakedOGY)}
          </Grid>
          <Grid container>
            <Button onClick={handleWithdraw}>Withdraw</Button>
            <Button onClick={() => setDepositOpen(true)}>Deposit</Button>
            <Button onClick={handleStakeToken}>Stake Token</Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            Staked OGY
            <br />
            {formatE8S(stakedOGY)}
          </Grid>
          <Grid container>
            {/* <Button disabled>No actions</Button> */}
            {/* <Button disabled>Deposit</Button> */}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            Staked Vesting OGY
            <br />
            {formatE8S(sumVesting)}
          </Grid>
          <Grid container>
            {/* <Button disabled>No actions</Button> */}
            {/* <Button disabled>Deposit</Button> */}
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Staked OGY
      </H2>
      <br />
      <Card column padding={2}>
        <Table
          grow
          columns={[
            "Index",
            "Created",
            "Start Vesting",
            "End Lock Time",
            "Quantity",
            "Dissolving",
            "Classification",
            "Total Rewards",
            "Rewards Redeemed",
            "Available to Redeem",
            "Actions",
          ]}
          data={dataStakedOGY}
        />
      </Card>
      <br />
      <br />
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Staked vestings (unvested)
        {/* (unvested) */}
      </H2>
      <br />
      <Card column padding={2}>
        <Table
          grow
          columns={[
            "Index",
            "Created",
            "Start Vesting",
            "End Lock Time",
            "Quantity",
            "Dissolving",
            "Classification",
            "Total Rewards",
            "Rewards Redeemed",
            "Available to Redeem",
            "Actions",
          ]}
          data={dataStakedVestingsOGY}
        />
      </Card>
      <br />
      <br />
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Vestings (unclaimed)
      </H2>
      {vestingBalances?.unclaimed?.length > 0 && (
        <>
          <Button onClick={handleClaim}>
            Claim your {vestingBalances.unclaimed.length} vesting schedules
          </Button>
          <br />
        </>
      )}
      <Table
        grow
        columns={[
          "Index",
          "Created",
          "Start Vesting",
          "End Lock Time",
          "Quantity",
          "Dissolving",
          "Classification",
          "Total Rewards",
          "Rewards Redeemed",
          "Available to Redeem",
          "Actions",
        ]}
        data={dataVestingUnclaimed}
      />
      <br />
      <br />
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Vestings (vestable)
      </H2>
      <Table
        grow
        columns={[
          "Index",
          "Created",
          "Start Vesting",
          "End Lock Time",
          "Quantity",
          "Dissolving",
          "Classification",
          "Total Rewards",
          "Rewards Redeemed",
          "Available to Redeem",
          "Actions",
        ]}
        data={dataVestingVestable}
      />
      <br />
      <br />
      <H2 margin={[0, 0, 3]} marginSsm={[0, 0, 4]}>
        Vestings (vested)
      </H2>
      <Table
        grow
        columns={[
          "Index",
          "Created",
          "Start Vesting",
          "End Lock Time",
          "Quantity",
          "Dissolving",
          "Classification",
          "Total Rewards",
          "Distributed Amount",
          "Available to Redeem",
          "Actions",
        ]}
        data={dataVestingVested}
      />

      {/* Manage Stake / Neuron Modal */}
      {/* Stake tokens modal */}
      <>
        <Modal isOpen={manageStakeOpen} onClose={handleManageStakedClose}>
          <Card column padding={2}>
            <H2>Manage stake (neuron)</H2>
            <br />
            {currentManagedStake && (
              <>
                <H5>Stake type: {currentManagedStake.type}</H5>
                <H5>
                  Created Date:{" "}
                  {timeConverter(
                    currentManagedStake.stakeObject[1].createdDate
                  )}
                </H5>
                {currentManagedStake.stakeObject[1].startDate && (
                  <H5>
                    Start Date:{" "}
                    {timeConverter(
                      currentManagedStake.stakeObject[1].startDate
                    )}
                  </H5>
                )}
                <H5>
                  End Lock Date:{" "}
                  {timeConverter(
                    currentManagedStake.stakeObject[1].endLockTime
                  )}
                  <Button
                    onClick={() =>
                      handleExtendStake(currentManagedStake.type, 1)
                    }
                  >
                    Extend one year
                  </Button>
                </H5>
                <H5>
                  Dissolving:{" "}
                  {currentManagedStake.stakeObject[1].dissolving ? "Yes" : "No"}
                  {currentManagedStake.stakeObject[1].dissolving ? (
                    <Button
                      onClick={() =>
                        handleUpdateDissolve(currentManagedStake.type, false)
                      }
                    >
                      Stop Dissolving
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        handleUpdateDissolve(currentManagedStake.type, true)
                      }
                    >
                      Dissolve
                    </Button>
                  )}
                </H5>
                <H5>
                  OGY Staked:{" "}
                  {formatE8S(currentManagedStake.stakeObject[1].quantity)}
                </H5>
                <H5>
                  Classification:{" "}
                  {currentManagedStake.stakeObject[1].classification}
                </H5>
                <br />
              </>
            )}
            {(window as any).debug &&
              JSONbig.stringify(currentManagedStake, null, 4)}

            <br />
            <Flex alignSelf='center'>
              <Flex gap={2}>
                <Button onClick={handleManageStakedClose}>Close</Button>
                {/* <Button onClick={handleManageStakeSave}>Save</Button> */}
              </Flex>
            </Flex>
          </Card>
        </Modal>
      </>

      {/* Stake tokens modal */}
      <>
        <Modal isOpen={createStakeOpen} onClose={handleCreateStakeClose}>
          <Card column padding={2}>
            <H2>Stake OGY (Create a neuron)</H2>
            <br />
            <H5>Available to stake: {formatE8S(unstakedOGY)} OGY</H5>

            <br />
            <H5>Create a stake</H5>
            <br />
            <H5>Stake Amount (OGY)</H5>
            <Input
              placeholder='Amount OGY'
              type='number'
              value={String(amount)}
              onChange={handleSetAmount}
            />
            <br />
            <H5>Lock</H5>
            <Button onClick={() => handleCreateStakeSave(1)}>
              Lock 1 year
            </Button>
            <Button onClick={() => handleCreateStakeSave(2)}>
              Lock 2 years
            </Button>
            <Button onClick={() => handleCreateStakeSave(3)}>
              Lock 3 years
            </Button>
            <Button onClick={() => handleCreateStakeSave(4)}>
              Lock 4 years
            </Button>

            {(window as any).debug && JSONbig.stringify(newStakeForm, null, 4)}
            {/* <H5>Recipient</H5>
            <Input
              placeholder='Recipient Account ID'
              value={recipient}
              onChange={handleSetRecipient}
            />
            
            <H5>Fee (OGY)</H5>
            <Input disabled placeholder='Fee (OGY)' value={String(FEE)} />
            <H5>Memo (Nat64)</H5>
            <Input
              placeholder='Memo (number)'
              type='number'
              onChange={handleSetMemo}
              value={String(memo)}
            /> */}
            <br />
            <Flex alignSelf='center'>
              <Flex gap={2}>
                <Button onClick={handleCreateStakeClose}>Close</Button>
              </Flex>
            </Flex>
          </Card>
        </Modal>
      </>

      {/* Withdraw tokens modal */}
      <>
        <Modal isOpen={sendOpen} onClose={handleOnCloseModal}>
          <Card column padding={2}>
            <H2>Withdraw OGY</H2>
            <br />
            <H5>
              Unstaked Balance: {"  "}
              {unstakedOGY !== undefined
                ? formatE8S(unstakedOGY) + " OGY"
                : "Loading..."}
            </H5>
            <br />
            <H5>Recipient</H5>
            <Input
              placeholder='Recipient Account ID'
              value={recipient}
              onChange={handleSetRecipient}
            />
            <H5>Amount (OGY)</H5>
            <Input
              placeholder='Amount OGY'
              type='number'
              value={String(amount)}
              onChange={handleSetAmount}
            />
            <H5>Fee (OGY)</H5>
            <Input disabled placeholder='Fee (OGY)' value={String(FEE)} />
            <H5>Memo (Nat64)</H5>
            <Input
              placeholder='Memo (number)'
              type='number'
              onChange={handleSetMemo}
              value={String(memo)}
            />
            <br />
            <Flex alignSelf='center'>
              <Flex gap={2}>
                <Button onClick={handleOnCloseModal}>Close</Button>
                <Button onClick={handleWithdrawOGY}>Withdraw OGY</Button>
              </Flex>
            </Flex>
          </Card>
        </Modal>
      </>

      {/* Deposit OGY to Governance Canister */}

      <>
        <Modal isOpen={depositOpen} onClose={() => setDepositOpen(false)}>
          <Card column padding={2}>
            <H2>Deposit OGY from wallet</H2>
            <br />
            <H5>
              Wallet Balance: {"  "}
              {walletBalance !== undefined
                ? formatE8S(walletBalance) + " OGY"
                : "Loading..."}
            </H5>
            <br />
            <H5>Amount (OGY)</H5>
            <Input
              placeholder='Amount OGY'
              type='number'
              value={String(amount)}
              onChange={handleSetAmount}
            />
            <H5>Fee (OGY)</H5>
            <Input disabled placeholder='Fee (OGY)' value={String(FEE)} />
            <br />
            <Flex alignSelf='center'>
              <Flex gap={2}>
                <Button onClick={() => setDepositOpen(false)}>Close</Button>
                <Button onClick={handleDepositOGY}>Deposit OGY</Button>
              </Flex>
            </Flex>
          </Card>
        </Modal>
      </>
    </div>
  );
};

export default Stakes;
