export const timeConverter = (timestamp: bigint) => {
  const a = new Date(Number(timestamp / 1000000n));
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  const time = hour + ":" + min + ":" + sec;
  return date + "/" + month + "/" + year;
};
