import React, {memo, useContext} from "react";
import Hidden from "@origyn-sa/ui/components/molecules/Hidden/index";
import AppBar from "@origyn-sa/ui/components/organisms/AppBar/index";
import SideBar from "@origyn-sa/ui/components/organisms/SideBar/index";
import {Link, useHistory} from "react-router-dom";
import {AuthContext} from "../hooks/auth";
import LogOutIcon from "@origyn-sa/ui/icons/logout";
import WalletIcon from "@origyn-sa/ui/icons/wallet";
import ExploreIcon from "@origyn-sa/ui/icons/explore";
import CollectionsIcon from "@origyn-sa/ui/icons/collections";
import InventoryIcon from "@origyn-sa/ui/icons/inventory";


const Navigation = () => {
  const { loggedIn, logOut } = useContext(AuthContext);
  const history = useHistory();

  const nav = [
    {
      label: "Wallet",
      Icon: WalletIcon,
      url: "/account",
      onItemSelect: () => history.push("/account"),
    },
    {
      label: "Governance",
      Icon: InventoryIcon,
      url: "/stakes",
      onItemSelect: () => history.push("/stakes"),
    },
    // {
    //   label: "Vesting",
    //   Icon: CollectionsIcon,
    //   url: "/vesting",
    //   onItemSelect: () => history.push("/vesting"),
    // },
    {
      label: "OGY Explorer",
      Icon: ExploreIcon,
      // url: "/stats",
      onItemSelect: () => window.open("https://explorer.origyn.network"),
    },
  ];

  const profileNav = [
    {
      label: "Log out",
      Icon: LogOutIcon,
      url: "/login",
      separate: true,
      onItemSelect: logOut,
    },
  ];

  if (!loggedIn) return null;

  return (
    <>
      <Hidden xsmUp>
        <AppBar
          zIndex={500}
          placing='sticky'
          position={[0]}
          navigation={nav}
          profileNavigation={profileNav}
          logoProps={{src: "/images/logo.png", onClick: () => history.push("/")}}
        />
      </Hidden>
      <Hidden xsmDown>
        <SideBar
          placing='sticky'
          position={[0]}
          shrink={0}
          navigation={nav}
          logoProps={{src: "/images/logo.png", onClick: () => history.push("/")}}
        />
      </Hidden>
    </>
  );
};

export default Navigation;