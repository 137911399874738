import { AppThemePartial } from '@origyn-sa/ui/utils/themeTypes'

export const lightTheme: AppThemePartial = {
  isDark: false,
  palette: {
    primary: 'rgb(68, 124, 233)',
    error: 'rgb(229, 24, 32)',
    warning: 'rgb(252, 156, 13)',
    success: 'rgb(45, 189, 129)',

    neutral1: 'rgb(41, 40, 56)',
    neutral2: 'rgb(111, 111, 111)',
    neutral3: 'rgb(180, 180, 180)',
    neutral4: 'rgb(221, 221, 221)',

    background1: 'rgb(255, 255, 255)',
    background2: 'rgb(250, 250, 250)',
    background3: 'rgb(237, 242, 253)',
    background4: 'rgb(243, 243, 243)',

    backdrop: 'rgba(0, 0, 0, 0.2)',
  },
  fontFamily: {
    main: 'Montserrat',
  },
  lineHeight: {
    sm: 1.3,
    md: 1.5,
    lg: 1.7,
  },
  fontWeight: {
    light: 400,
    normal: 500,
    bold: 600,
  },
  fontSize: {
    sm: 11,
    md: 12,
    lg: 14,
    h1: 44,
    h2: 32,
    h3: 20,
    h4: 18,
    h5: 14,
    h6: 12,
  },
}

export const darkTheme: AppThemePartial = {
  isDark: true,
  palette: {
    primary: 'rgb(68, 124, 233)',
    error: 'rgb(229, 24, 32)',
    warning: 'rgb(252, 156, 13)',
    success: 'rgb(45, 189, 129)',

    neutral1: 'rgb(255, 255, 255)',
    neutral2: 'rgb(115, 117, 155)',
    neutral3: 'rgb(60, 61, 91)',
    neutral4: 'rgb(47, 48, 66)',

    background1: 'rgb(37, 39, 54)',
    background2: 'rgb(30, 29, 43)',
    background3: 'rgb(47, 48, 66)',
    background4: 'rgb(47, 48, 66)',

    backdrop: 'rgba(0, 0, 0, 0.2)',
  },
  fontFamily: {
    main: 'Montserrat',
  },
  lineHeight: {
    sm: 1.3,
    md: 1.5,
    lg: 1.7,
  },
  fontWeight: {
    light: 400,
    normal: 500,
    bold: 600,
  },
  fontSize: {
    sm: 11,
    md: 12,
    lg: 14,
    h1: 44,
    h2: 32,
    h3: 20,
    h4: 18,
    h5: 14,
    h6: 12,
  },
}
