import React, {useContext} from "react";
import Card from "@origyn-sa/ui/components/atoms/Card";
import Text from "@origyn-sa/ui/components/atoms/Text";
import Flex from "@origyn-sa/ui/components/atoms/Flex";
import Button from "@origyn-sa/ui/components/molecules/Button";
import { H2, H3 } from "@origyn-sa/ui/components/atoms/H";
import Block from "@origyn-sa/ui/components/atoms/Block";
import Grid from "@origyn-sa/ui/components/atoms/Grid";
import {AuthContext} from "../../hooks/auth";
import useBalance from "../../hooks/balance";
import WebIcon from '@material-ui/icons/Web';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ComputerIcon from '@material-ui/icons/Computer';
import {useHistory} from "react-router-dom";

import WalletInfo from "../../components/WalletInfo";

const Login = () => {
  const { logIn, loggedIn, isAuthorized } = useContext(AuthContext);
  const history = useHistory();

  const handleLogIn = (wallet: string) => {
      logIn(wallet).then(() => {
        if (wallet !== "ii" || isAuthorized) {
          history.push("/account")
        }
      });
  }

  return (
    <div>
      {
        isAuthorized === false && (
          <H3>Please try again</H3>
        )
      }
      {
        isAuthorized === true && (
          <>
            <H3>Connected with Internet Identity</H3>
          </>
        )
      }
      {
        loggedIn ? (
          <>
            <Flex
              column
              align="center"
              justify="center"
              gap={5}
            >
              <WalletInfo />
            </Flex>
          </>
        ) : (
          <>
            <H2>Connection method: </H2>
            <br/>
            <br/>
            <Grid
              columns={1} columnsSm={3}
              gap={2}
            >
              <Card
                overflow="hidden"
              >
                <Flex
                  column
                  width="100%"
                >
                  <Block
                    background="neutral1"
                    color="neutral4"
                    padding={2}
                    textAlign="center"
                  >
                    <Flex
                      gap={2}
                      justify="center"
                      align="center"
                    >
                      <img style={{objectFit: "contain"}} src="/images/icp.png" alt="Internet Identity" />
                      <H3>Internet Identity</H3>
                    </Flex>
                  </Block>
                  <Block
                    padding={2}
                  >
                    <Flex>
                      <Flex gap={1}><PhoneIphoneIcon fontSize="small" />Cross Platform Application</Flex>
                    </Flex>
                  </Block>
                  <Block
                    padding={2}
                  >
                    <Text>Internet Identity, a blockchain authentication system for the Internet Computer.</Text>
                    {/* <Text>Internet Identety - your personal space on the Internet Computer.</Text> */}
                  </Block>
                  <Flex
                    padding={2}
                    column
                    grow={1}
                    justify="flex-end"
                  >
                    <Button onClick={() => window.open("https://identity.ic0.app/", '_blank')?.focus()} kind="outlined" width="100%">More Info</Button>
                    <br/>
                    <Button  onClick={() => handleLogIn("ii")} width="100%">Connect</Button>
                  </Flex>
                </Flex>
              </Card>
              {/* <Card
                overflow="hidden"
              >
                <Flex
                  column
                  width="100%"
                >
                  <Block
                    background="neutral1"
                    color="neutral4"
                    padding={2}
                    textAlign="center"
                  >
                    <Flex
                      gap={2}
                      justify="center"
                      align="center"
                    >
                      <img height={30} style={{objectFit: "contain"}} src="/images/stoic-logo.png" alt="Internet Identity" />
                      <H3>Stoic Wallet</H3>
                    </Flex>
                  </Block>
                  <Block
                    padding={2}
                  >
                    <Flex>
                      <Flex gap={1}><ComputerIcon fontSize="small" /> Web Application</Flex>
                    </Flex>
                  </Block>
                  <Block
                    padding={2}
                  >
                    <Text>Stoic Wallet is a quick to set up wallet with marketplace and other features</Text>
                  </Block>
                  <Flex
                    padding={2}
                    column
                    grow={1}
                    justify="flex-end"
                  >
                    <Button onClick={() => window.open("https://www.stoicwallet.com/", '_blank')?.focus()} kind="outlined" width="100%">More Info</Button>
                    <br/>
                    <Button onClick={() => handleLogIn("stoic")} width="100%">Connect</Button>
                  </Flex>
                </Flex>
              </Card> */}
              <Card
                overflow="hidden"
              >
                <Flex
                  column
                  width="100%"
                >
                  <Block
                    background="neutral1"
                    color="neutral4"
                    padding={2}
                    textAlign="center"
                  >
                    <Flex
                      gap={2}
                      justify="center"
                      align="center"
                    >
                      <img height={30} style={{objectFit: "contain"}} src="/images/plug-logo.png" alt="Internet Identity" />
                      <H3>Plug Wallet</H3>
                    </Flex>
                  </Block>
                  <Block
                    padding={2}
                  >
                    <Flex>
                      <Flex gap={1}><WebIcon fontSize="small" /> Browser Extension</Flex>
                    </Flex>
                  </Block>
                  <Block
                    padding={2}
                  >
                    <Text>Plug is a browser extension and handles your keys and data directly from your computer</Text>
                  </Block>
                  <Flex
                    padding={2}
                    column
                    grow={1}
                    justify="flex-end"
                  >
                    <Button onClick={() => window.open("https://plugwallet.ooo/", '_blank')?.focus()} kind="outlined" width="100%">More Info</Button>
                    <br/>
                    <Button onClick={() => handleLogIn("plug")} width="100%">Connect</Button>
                  </Flex>
                </Flex>
              </Card>
            </Grid>
          </>
        )
      }
    </div>
  );
};

export default Login;
