export const idlFactory = ({ IDL }) => {
  const EmailHashStatus = IDL.Variant({
    'TokensTransferred' : IDL.Null,
    'SubscriberCreated' : IDL.Null,
    'Initial' : IDL.Null,
  });
  const EmailHashData = IDL.Record({
    'ogy' : IDL.Nat32,
    'status' : IDL.Opt(EmailHashStatus),
    'hash' : IDL.Text,
    'created_at' : IDL.Opt(IDL.Nat64),
    'email' : IDL.Text,
  });
  const RegistrationStats = IDL.Record({
    'subscriptions' : IDL.Nat32,
    'failed_registrations' : IDL.Nat32,
    'limit' : IDL.Nat32,
    'registrations' : IDL.Nat32,
  });
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : IDL.Text });
  const Registration = IDL.Record({
    'account_id' : IDL.Opt(IDL.Text),
    'principal' : IDL.Principal,
    'uuid' : IDL.Text,
    'email' : IDL.Text,
  });
  return IDL.Service({
    'add_admin' : IDL.Func([IDL.Principal], [], []),
    'get_next_emails' : IDL.Func([], [IDL.Vec(EmailHashData)], []),
    'get_registration_stats' : IDL.Func([], [RegistrationStats], []),
    'get_verified_hashes' : IDL.Func([], [IDL.Vec(EmailHashData)], []),
    'is_used_qr_uuid' : IDL.Func([IDL.Text], [IDL.Bool], ['query']),
    'is_valid_qr_uuid' : IDL.Func([IDL.Text], [IDL.Bool], ['query']),
    'perform_airdrop' : IDL.Func([IDL.Text, IDL.Nat64], [Result], []),
    'register' : IDL.Func(
        [IDL.Text, IDL.Opt(IDL.Text), IDL.Text],
        [Result],
        [],
      ),
    'registered_accounts' : IDL.Func([], [IDL.Vec(IDL.Text)], []),
    'registrations' : IDL.Func([], [IDL.Vec(Registration)], []),
    'set_registrations_limit' : IDL.Func([IDL.Nat32], [], []),
    'set_verified_hash' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Nat32, IDL.Text],
        [],
        [],
      ),
    'shutdown' : IDL.Func([], [], []),
    'sync_mailchimp' : IDL.Func([], [Result], ['query']),
    'transfer_token_by_hash' : IDL.Func([IDL.Text], [Result], []),
    'transfer_token_to_principal' : IDL.Func(
        [IDL.Text, IDL.Principal],
        [Result],
        [],
      ),
    'update_registration' : IDL.Func(
        [
          IDL.Text,
          IDL.Opt(IDL.Principal),
          IDL.Opt(IDL.Text),
          IDL.Opt(IDL.Text),
        ],
        [Result],
        [],
      ),
    'upload_registration_data' : IDL.Func(
        [IDL.Vec(Registration), IDL.Vec(Registration)],
        [],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return []; };
