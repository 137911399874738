import React from "react";
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Stakes from "./pages/Stakes";
import Vesting from "./pages/Vesting";
import Stats from "./pages/Stats";
import Account from "./pages/Account";
import Profile from "./components/Profile";
import Navigation from "./components/Navigation";
import Flex from "@origyn-sa/ui/components/atoms/Flex";
import Provider from "@origyn-sa/ui";
import {lightTheme} from "./theme";
import {AuthProvider} from "./hooks/auth";
import Toaster from "@origyn-sa/ui/components/organisms/Toaster/index";
import PromoRetrieve from "./pages/Promo";
import 'react-toastify/dist/ReactToastify.css';
import Terms from "./pages/Terms";

const WalletPath = ({ match, location }) => {
  console.log(location);
  if (location.pathname === "/promo") {
    return null;
  }
  return (
    <Flex
      column
      columnXsm={false}
      minHeight='100vh'
    >
      <Navigation/>
      <Flex
        grow
        column
        minWidth={0}
        padding={[3, 2]}
        paddingSsm={[3, 4]}
        paddingXsm={[4, 4]}
        paddingXlg={[4, 5]}
        overflow='hidden'
      >
        <Profile/>
        <Flex grow column className='growChildren'>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/login">
            <Login/>
          </Route>
          <Route exact path="/terms">
            <Terms/>
          </Route>
          <Route exact path="/account">
            <Account/>
          </Route>
          <Route exact path="/stakes">
            <Stakes/>
          </Route>
          <Route exact path="/vesting">
            <Vesting/>
          </Route>
          <Route exact path="/stats">
            <Stats/>
          </Route>
        </Flex>
      </Flex>
    </Flex>
  );
}

const App = () => {
  return (
    <Provider theme={lightTheme}>
      <Router>
        <Switch>
        <AuthProvider>
          <Route exact path="/promo">
            <PromoRetrieve/>
          </Route>
          <Route path="/" component={WalletPath} />
        </AuthProvider>
        </Switch>
        <Toaster />
      </Router>
    </Provider>
  );
};

export default App;
