import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../auth";
import useStoicBalance from "./stoic";
import usePlugBalance from "./plug";
import useIIBalance from "./ii";

const useBalance = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stakedTokens, setStakedTokens] = useState<any>();
  const [walletBalance, setWalletBalance] = useState<BigInt>();
  const [tokens, setTokens] = useState<Array<any>>();
  const getStoicBalance = useStoicBalance();
  const getPlugBalance = usePlugBalance();
  const getIIBalance = useIIBalance();
  const {
    walletType,
    loggedIn,
    isLoading: isAuthLoading,
  } = useContext(AuthContext);

  const getBalance = async () => {
    switch (walletType) {
      case "stoic":
        setWalletBalance(await getStoicBalance());
        break;
      case "plug":
        const result = await getPlugBalance();
        setWalletBalance(result);
        break;
      case "ii": {
        const result = await getIIBalance();
        console.log("ii balance", result);
        setWalletBalance(result || 0n);
        break;
      }
    }
    setStakedTokens([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isAuthLoading && loggedIn) {
      getBalance();
    }
  }, [loggedIn, isAuthLoading]);

  return {
    isLoading,
    stakedTokens,
    tokens,
    walletBalance,
  };
};

export default useBalance;
