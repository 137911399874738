import React, { useContext } from "react";
import Card from "@origyn-sa/ui/components/atoms/Card";
import Flex from "@origyn-sa/ui/components/atoms/Flex";
import Button from "@origyn-sa/ui/components/molecules/Button";
import { H1, H2, H3 } from "@origyn-sa/ui/components/atoms/H";
import HR from "@origyn-sa/ui/components/atoms/HR";
import Block from "@origyn-sa/ui/components/atoms/Block";
import Grid from "@origyn-sa/ui/components/atoms/Grid";
import useMetrics from "../../hooks/metrics";
import Slider from "../../components/Slider";
import { AuthContext } from "../../hooks/auth";
import { Link } from "react-router-dom";

const Home = () => {
  const metrics = useMetrics();
  const { loggedIn } = useContext(AuthContext);

  console.log(metrics);
  return (
    <div>
      <br />
      <br />
      <br />
      <H1 textAlign='center'>Token Metrics</H1>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Flex
        gap={8}
        align='center'
        justify='center'
        textAlign='center'
        column
        columnSm={false}
      >
        <div>
          <H2>Total OGY</H2>
          <H3>10~ Billions</H3>
        </div>
        <HR margin={0} />
        <div>
          <H2>Transactions</H2>
          <H3>
            <a href='https://explorer.origyn.network' target='_blank'>
              Go to Explorer
            </a>
          </H3>
        </div>
        {/* <HR margin={0}/>
        <div>
          <H2>Holders</H2>
          <H3>-</H3>
        </div> */}
      </Flex>
      <br />
      <br />
      <Flex gap={8} align='center' justify='center' textAlign='center'>
        {" "}
        <Link to='/login'>
          <Button>Log In</Button>
        </Link>
      </Flex>{" "}
      <br />
      <br />
      <H2 textAlign='center'>Supported Wallets</H2>
      <br />
      <Grid columns={1} columnsSm={3} gap={2}>
        <Card overflow='hidden'>
          <Flex column width='100%'>
            <Block
              background='neutral1'
              color='neutral4'
              padding={2}
              textAlign='center'
            >
              <Flex gap={2} justify='center' align='center'>
                <img
                  style={{ objectFit: "contain" }}
                  src='/images/icp.png'
                  alt='Internet Identity'
                />
                <H3>Internet Identity</H3>
              </Flex>
            </Block>
            <Flex padding={2} column grow={1} justify='flex-end'>
              <Button
                onClick={() =>
                  window.open("https://identity.ic0.app/", "_blank")?.focus()
                }
                kind='outlined'
                width='100%'
              >
                Go to wallet
              </Button>
            </Flex>
          </Flex>
        </Card>
        {/* <Card overflow='hidden'>
          <Flex column width='100%'>
            <Block
              background='neutral1'
              color='neutral4'
              padding={2}
              textAlign='center'
            >
              <Flex gap={2} justify='center' align='center'>
                <img
                  height={30}
                  style={{ objectFit: "contain" }}
                  src='/images/stoic-logo.png'
                  alt='Internet Identity'
                />
                <H3>Stoic Wallet</H3>
              </Flex>
            </Block>
            <Flex padding={2} column grow={1} justify='flex-end'>
              <Button
                onClick={() =>
                  window.open("https://www.stoicwallet.com/", "_blank")?.focus()
                }
                kind='outlined'
                width='100%'
              >
                Go to wallet
              </Button>
            </Flex>
          </Flex>
        </Card> */}
        <Card overflow='hidden'>
          <Flex column width='100%'>
            <Block
              background='neutral1'
              color='neutral4'
              padding={2}
              textAlign='center'
            >
              <Flex gap={2} justify='center' align='center'>
                <img
                  height={30}
                  style={{ objectFit: "contain" }}
                  src='/images/plug-logo.png'
                  alt='Internet Identity'
                />
                <H3>Plug Wallet</H3>
              </Flex>
            </Block>
            <Flex padding={2} column grow={1} justify='flex-end'>
              <Button
                onClick={() =>
                  window.open("https://plugwallet.ooo/", "_blank")?.focus()
                }
                kind='outlined'
                width='100%'
              >
                Go to wallet
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Block
        background='neutral1'
        color='neutral4'
        textAlign='center'
        padding={8}
      >
        <H2>Other ORIGYN Products</H2>
        <br />
        <Flex
          justify='center'
          align='center'
          column
          columnSm={loggedIn}
          columnMd={false}
          wrapSm
        >
          <a href='https://www.origyn.ch/luxury'>
            <img
              style={{ objectFit: "contain" }}
              src='https://www.origyn.ch/build/img/logo-luxury.png'
              alt='logo-luxury'
            />
          </a>
          <a href='https://www.origyn.ch/digital-media'>
            <img
              style={{ objectFit: "contain" }}
              src='https://www.origyn.ch/build/img/logo-digital-media.png'
              alt='logo-digital-media'
            />
          </a>
          <a href='https://www.origyn.ch/art'>
            <img
              style={{ objectFit: "contain" }}
              src='https://www.origyn.ch/build/img/logo-art.png'
              alt='logo-art'
            />
          </a>
        </Flex>
      </Block>
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <br />
      <br />
      <Block background='neutral1' color='neutral4'>
        <br />
        <H1 textAlign='center'>Features</H1>
        <Slider>
          <Flex justify='center'>
            <Flex
              padding={8}
              width='70%'
              gap={4}
              align='center'
              column
              columnMd={loggedIn}
              columnLg={false}
            >
              <img
                style={{ objectFit: "contain", flexShrink: 0, width: "auto" }}
                src='https://www.origyn.ch/build/img/logo-luxury.png'
                alt='logo-luxury'
              />
              <HR />
              <Block padding={4} textAlign='left'>
                <H1>ORIGIN Luxury</H1>
                <H2>Origyn Luxury item marketplace etc.</H2>
              </Block>
            </Flex>
          </Flex>
          <Flex justify='center'>
            <Flex
              padding={8}
              width='70%'
              gap={4}
              align='center'
              column
              columnMd={loggedIn}
              columnLg={false}
            >
              <img
                style={{ objectFit: "contain", flexShrink: 0, width: "auto" }}
                src='https://www.origyn.ch/build/img/logo-digital-media.png'
                alt='logo-digital-media'
              />
              <HR />
              <Block padding={4} textAlign='left'>
                <H1>ORIGIN Luxury</H1>
                <H2>
                  Origyn Luxury item marketplace etc.Origyn Luxury item
                  marketplace etc.Origyn Luxury item marketplace etc.
                </H2>
              </Block>
            </Flex>
          </Flex>
          <Flex justify='center'>
            <Flex
              padding={8}
              width='70%'
              gap={4}
              align='center'
              column
              columnMd={loggedIn}
              columnLg={false}
            >
              <img
                style={{ objectFit: "contain", flexShrink: 0, width: "auto" }}
                src='https://www.origyn.ch/build/img/logo-art.png'
                alt='logo-art'
              />
              <HR />
              <Block padding={4} textAlign='left'>
                <H1>ORIGIN Luxury</H1>
                <H2>
                  Origyn Luxury item marketplace etc.Origyn Luxury item
                  marketplace etc.
                </H2>
              </Block>
            </Flex>
          </Flex>
        </Slider>
      </Block> */}
    </div>
  );
};

export default Home;
