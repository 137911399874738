import React from "react";
import {Container, Flex, Card, theme} from "@origyn-sa/origyn-art-ui";
import {ThemeProvider} from "styled-components";

const Terms = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container size="lg" padding="32px" smPadding="12px" mdPadding="24px">
          <h2>Terms and Conditions</h2>
          <br/>
          <Card>
            <Container size="md" padding="32px" smPadding="12px" mdPadding="24px">
              <Flex flexFlow="column" gap={10}>
                <p>
                  The purpose of these Terms of Use is to define the terms and conditions of access and use of the site
                  (hereinafter the "Site"). The use of the Site is subject to compliance with these Terms of Use, which
                  all users acknowledge that they accept unreservedly by the mere fact of accessing the said Site. Any
                  user who does not wish to be bound by these Terms of Use must immediately renounce remaining on the Site
                  and immediately cease using it.
                </p>
                <p>
                  1. The NFT Paris OGY Promotion (the "Promotion") is only open to individuals who sign up using this Site
                  and are 13 years of age as of the date of entry. Restrictions due to compliance regulations for
                  different jurisdictions apply. Residents from China, the United States of America, South Korea, Nepal,
                  Bangladesh, Macedonia, Bolivia, Ecuador, India, Pakistan, Algeria, and Morocco are not permitted to join
                  this contest.
                </p>
                <p>
                  2. By entering your information on this Site, you agree to comply with and follow all of the rules
                  outlined in these terms and conditions, and you hereby represent that you meet the eligibility
                  requirements to participate.
                </p>
                <p>
                  3. The Promotion begins on January 22, 2022 at 12:00AM CET and ends on January 25 at 11:59PM CET (the
                  "Entry Period"). Entries submitted before or after the Entry Period will not be eligible.
                </p>
                <p>
                  4. The entry must fulfill all requirements of the Promotion, as specified, to be eligible to receive OGY
                  tokens, and only the first 150 individuals to enter will receive tokens. Entries that are incomplete or
                  do not follow the rules or specifications of the Promotion may be disqualified at ORIGYN Foundation’s
                  sole discretion. You must provide all of the information requested, including your email address and
                  Principle ID. You may not enter more than once by using multiple email addresses, Internet Identities or
                  devices, else your submission may be disqualified at the sole discretion of ORIGYN Foundation.
                </p>
                <p>Actual/appraised value of prizes may differ at time of prize award. The specifics of the prize shall be
                  solely determined by ORIGYN Foundation. No cash or other prize substitutions shall be permitted by
                  ORIGYN Foundation. Substitution of tokens or transfer/assignment of the tokens to others or request for
                  the cash equivalent of the tokens is not permitted. Any and all token-related expenses, including
                  without limitation any and all federal, state, and/or local taxes shall be the sole responsibility of
                  the Winner.
                </p>
                <p>ORIGYN Foundation reserves the right, in its sole discretion, to cancel, terminate, modify or
                  suspend the Promotion should virus, bug, non-authorised human intervention, fraud, or other cause beyond
                  ORIGYN Foundation’s control corrupt or affect the administration, security, fairness, or proper conduct
                  of
                  the Promotion. Any attempt by an entrant to deliberately damage any website or undermine the legitimate
                  operation of the Promotion may be a violation of criminal and civil laws. Should such an attempt be
                  made,
                  ORIGYN Foundation reserves the right to seek damages to the fullest extent permitted by law.
                </p>
                <p>By entering your information to receive OGY tokens, you agree to release and hold harmless ORIGYN
                  Foundation and its subsidiaries, affiliates, advertising and promotion agencies, partners,
                  representatives, agents, successors, assigns, employees, officers and directors from any liability,
                  illness, injury, death, loss, litigation, claim or damage that may occur, directly or indirectly,
                  whether
                  caused by negligence or not, from (i) such entrant’s participation in the Promotion and/or his/her
                  acceptance, possession, use, or misuse of any prize or any portion thereof; (ii) unauthorised human
                  intervention in any part of the Promotion; (iii) electronic or human error in the administration of the
                  Promotion or the processing of entries; (iv) technical errors of any kind, including but not limited to
                  the malfunction of any computer, cable, network, hardware, or software, or other mechanical equipment;
                  (v)
                  the unavailability or inaccessibility of any transmissions, telephone, or Internet service; (vi)
                  printing
                  errors; (vii) lost, late, postage due, misdirected, or undeliverable mail.
                </p>
                <p>This Promotion is governed by the laws of Switzerland, without respect to conflict of law doctrines.
                  By participating in this Promotion, you agree that any and all disputes that cannot be resolved between
                  the parties, and causes of action arising out of or connected with this Promotion, shall be resolved
                  individually, without resort to any form of class action, exclusively before a court located in
                  Switzerland having jurisdiction. Further, in any such dispute, under no circumstances shall You be
                  permitted to obtain awards for, and hereby waives all rights to, punitive, incidental, or consequential
                  damages, including reasonable attorney’s fees, other than actual out-of-pocket expenses (i.e. costs
                  associated with entering the Promotion). You further waive all rights to have damages multiplied or
                  increased.
                </p>
                <p>Information submitted with an entry is subject to the Privacy Policy stated on the ORIGYN Foundation
                  Website. To read the Privacy Policy, click here.
                </p>
                <p>In particular, with regard to the financial information that may be provided on this Site, the only
                  authentic reference is the Swiss version of the document filed with the Swiss Financial Markets
                  Authority
                  (“Autorité fédérale de surveillance des marchés financiers FINMA”), a document available from ORIGYN'
                  Investor Relations Department. All or any of the information published on this Site is provided "as is"
                  without warranty of any kind, either express or implied, including, but not limited to, warranties of
                  merchantability, fitness for a particular purpose or non-infringement of third party rights. ORIGYN
                  provides no guarantee as to the accuracy or completeness of the elements and/or information published on
                  this Site. ORIGYN in no way guarantees uninterrupted access to this Site as well as the security of the
                  Site and the absence of all viruses or other undesirable hosts (including in the elements of the Site
                  and
                  in the information incorporated therein). The use of the elements of the Site and any information
                  included
                  therein as well as access to this Site are the sole responsibility of the user. ORIGYN disclaims
                  liability
                  for any damage whatsoever, including without limitation direct and indirect damage that may result from
                  access to this Site and the use of all or part of the elements or information contained therein.
                </p>
                <p>Any information or request for information that you may send to ORIGYN via the Site or via the
                  electronic mailbox to which the Site may provide access, is considered to be non-confidential. You may
                  also send your information or requests by post to the address of ORIGYN' head office for the attention
                  of
                  the department identified in the corresponding section of this Site.
                </p>
                <p>
                  NOTICE TO RESIDENTS OF THE UNITED STATES: THE OFFER OF THIS SECURITY INSTRUMENT HAS NOT BEEN REGISTERED
                  UNDER THE U.S. SECURITIES ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”), THE SECURITIES LAWS OF ANY
                  STATE
                  OR ANY OTHER
                  APPLICABLE SECURITIES LAWS IN RELIANCE UPON EXEMPTIONS FROM THE REGISTRATION REQUIREMENTS OF THE
                  SECURITIES ACT AND SUCH LAWS. THIS INSTRUMENT MAY NOT BE OFFERED FOR SALE, PLEDGED, HYPOTHECATED, SOLD,
                  ASSIGNED OR TRANSFERRED AT ANY TIME EXCEPT IN COMPLIANCE WITH THE SECURITIES ACT, ANY APPLICABLE STATE
                  SECURITIES LAWS AND ANY OTHER APPLICABLE SECURITIES LAWS AND THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                  THEREFORE, PURCHASERS OF THIS INSTRUMENT WILL BE REQUIRED TO BEAR THE RISK OF THEIR INVESTMENT FOR AN
                  INDEFINITE PERIOD OF TIME. NOTICE TO RESIDENTS OF ANY OTHER COUNTRY OR JURISDICTION THIS DOCUMENT DOES
                  NOT
                  CONSTITUTE AN OFFER TO SELL OR SOLICITATION OF AN OFFER TO BUY ANY SECURITY OR OTHER INSTRUMENT IN ANY
                  JURISDICTION OTHER THAN THOSE LISTED ABOVE. PLEASE ALSO NOTE THAT THIS DOCUMENT HAS NEITHER BEEN
                  SUBMITTED
                  NOR APPROVED BY THE SWISS FINANCIAL MARKET SUPERVISORY AUTHORITY (FINMA)
                </p>

              </Flex>
            </Container>
          </Card>
        </Container>
      </ThemeProvider>
    </>
  );
};
export default Terms;
