import React from "react";
import CopyIcon from "@material-ui/icons/FileCopy";
import styled from "styled-components";
import {copyToClipboard} from "../utils";

const StyledButton = styled.span`
  cursor: pointer;
`

const CopyButton = ({text = ""}: {text: string}) => {
  return (
    <StyledButton onClick={() => copyToClipboard(text)}>
      <CopyIcon fontSize="small" />
    </StyledButton>
  )
}

export default CopyButton;
